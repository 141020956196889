import { Tooltip as TOOLTIP } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const Tooltip = styled(({ className, arrow = true, ...props }) => {
  return <TOOLTIP {...props} arrow={arrow} classes={{ popper: className }} />;
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2B2F38',
    fontSize: 13,
    fontWeight: '400',
    padding: 8,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2B2F38',
  },
}));

export default Tooltip;
