import React from 'react';
import * as ROUTES from '../navigation/constants';
import Loader from '../components/shared/Loader';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';

const AuthGuard = ({ children }) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!user) {
    navigate(ROUTES.SIGN_IN);
    return null;
  }

  return children;
};

export default AuthGuard;
