import {
  query,
  collection,
  where,
  getDocs,
  updateDoc,
  doc,
  setDoc,
} from 'firebase/firestore';
import { localKeys } from '../../constants/localStorage';

import { getLocalUser } from '../../utils/localStorage';
import { auth, db } from '../config';
import { PROFILE, PROFILE_LOGIN, REVIEWER } from '../constants';

const user = getLocalUser(localKeys.AUTH);

// Get ProfileData data from db using uid from profile collection
export const getProfileData = (id = '') =>
  new Promise((resolve) => {
    const getActivitiesQuery = query(
      collection(db, PROFILE),
      where('uid', '==', id !== '' ? id : auth?.currentUser?.uid || user?.uid),
      where('deleteDocument', '==', false)
    );
    let fetchedProfiles = [];
    getDocs(getActivitiesQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfile = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfiles.push(fetchedProfile);
        });
        resolve(fetchedProfiles[0]);
      })
      .catch((error) => {
        resolve(fetchedProfiles);
      });
  });

export const getProfileDataByEmail = (email) =>
  new Promise((resolve) => {
    const getActivitiesQuery = query(
      collection(db, PROFILE),
      where('email', '==', email),
      where('deleteDocument', '==', false)
    );
    let fetchedProfiles = [];
    getDocs(getActivitiesQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfile = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfiles.push(fetchedProfile);
        });
        resolve(fetchedProfiles[0]);
      })
      .catch((error) => {
        resolve(fetchedProfiles);
      });
  });

export const getProfileLogInData = () =>
  new Promise((resolve) => {
    const getParentsDataQuery = query(
      collection(db, PROFILE_LOGIN),
      where('createdBy', '==', auth?.currentUser?.uid || user?.uid),
      where('deleteDocument', '==', false)
    );
    let fetchedProfileLogins = [];
    getDocs(getParentsDataQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfileLogIn = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfileLogins.push(fetchedProfileLogIn);
        });
        resolve(fetchedProfileLogins[0]);
      })
      .catch((error) => {
        resolve('');
      });
  });

// ADD METHODS
// Add Log in Information
export const addLogInInfo = (logInInfo) =>
  new Promise((resolve, reject) => {
    const newEssayRef = doc(collection(db, PROFILE_LOGIN));
    setDoc(newEssayRef, {
      ...logInInfo,
      id: newEssayRef?.id,
      createdAt: new Date(),
      createdBy: auth?.currentUser?.uid,
      deleteDocument: false,
    })
      .then(() => resolve(true))
      .catch(() => reject(false));
  });

// UPDATE METHODS

// Edit Profile Log in info
export const editProfileLogInInfo = (logInInfo, docId) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, PROFILE_LOGIN, docId), logInInfo)
      .then(() => resolve(true))
      .catch((error) => reject(false));
  });

//Get Student Details
export const getStudentData = (email) =>
  new Promise((resolve) => {
    const getChildrenDatQuery = query(
      collection(db, REVIEWER),
      where('deleteDocument', '==', false),
      where('email', '==', email)
    );
    let fetchedProfileLogins = [];
    getDocs(getChildrenDatQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfileLogIn = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfileLogins.push(fetchedProfileLogIn);
        });
        resolve(fetchedProfileLogins);
      })
      .catch((error) => {
        resolve('');
      });
  });
export const getProfileByUserId = (id) =>
  new Promise((resolve) => {
    const getActivitiesQuery = query(
      collection(db, PROFILE),
      where('uid', '==', id),
      where('deleteDocument', '==', false)
    );
    let fetchedProfiles = [];
    getDocs(getActivitiesQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfile = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfiles.push(fetchedProfile);
        });
        resolve(fetchedProfiles);
      })
      .catch((error) => {
        resolve(fetchedProfiles);
      });
  });
export const getProfileByReviewerId = (id) =>
  new Promise((resolve) => {
    const getActivitiesQuery = query(
      collection(db, REVIEWER),
      where('id', '==', id)
    );
    let fetchedProfiles = [];
    getDocs(getActivitiesQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfile = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfiles.push(fetchedProfile);
        });
        resolve(fetchedProfiles);
      })
      .catch((error) => {
        resolve(fetchedProfiles);
      });
  });
