import { useState } from 'react';
import { Divider } from '@mui/material';
import { InputField } from '../../shared/InputField';
import { PopupButton } from '../../shared/PopupButton';
import Button from '../../shared/Button';
import { SelectDateInput } from '../../shared/SelectDateInput';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getFormattedDate } from '../../../utils/helper';

export const AddLetterDetails = ({
  buttonText = '',
  letter = {},
  onAdd = () => {},
  startIcon = <AddCircleIcon />,
  isMobile = false,
  layoutSpread = false,
  ...props
}) => {
  const [anchor, setAnchor] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [requestedDetails, setRequestedDetails] = useState({
    writer: letter?.writer || '',
    date: letter?.date || '',
  });

  const openMenu = (e) => {
    setAnchor(e.currentTarget);
    setIsMenuOpen(true);
  };

  const closeMenu = (e) => {
    setAnchor(null);
    setIsMenuOpen(false);
  };
  return (
    <PopupButton
      buttonText={buttonText}
      startIcon={startIcon}
      anchorEl={anchor}
      open={isMenuOpen}
      onClose={closeMenu}
      onClick={openMenu}
      isMobile={isMobile}
      layoutSpread={layoutSpread}
      {...props}
    >
      <div className="flex flex-col gap-6">
        <div className="grid grid-cols-3 gap-6">
          <InputField
            label="Recommended Name"
            className="col-span-2"
            value={requestedDetails?.writer}
            onChange={(e) => {
              e.stopPropagation();
              setRequestedDetails({
                ...requestedDetails,
                writer: e.target.value,
              });
            }}
          />
          <SelectDateInput
            label="Requested On"
            className="col-span-1"
            value={
              requestedDetails?.date
                ? getFormattedDate(requestedDetails?.date)
                : ''
            }
            onChange={(newValue) =>
              setRequestedDetails({ ...requestedDetails, date: newValue.$d })
            }
          />
        </div>
        <Divider />
        <div className="flex gap-2 ml-auto">
          <Button variant="text" onClick={closeMenu}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onAdd(requestedDetails);
              closeMenu();
            }}
          >
            {letter?.data || letter?.writer ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </PopupButton>
  );
};
