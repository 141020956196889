import { styled } from '@mui/system';
import { Modal, Box, Button } from '@mui/material';

export const ModalCustomBlock = styled(Modal)({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(100, 109, 135, 0.5)',
  },
});
export const BoxCustomModal = styled(Box)({
  border: 'none !important',
  background: 'none !important',
  boxShadow: 'none !important',
  width: '580px !important',
  padding: '0px !important',
});

export const CustomButton = styled(Button)({
  padding: '12px 14px',
  borderRadius: '8px',
  marginTop: '8px',
  '&:hover': {
    background: 'rgba(25, 118, 210, 0.04)',
  },
  p: {
    fontSize: '14px',
    paddingLeft: '10px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
    fontWeight: '500',
  },
  '&.btn-text-cancel': {
    marginTop: '0px',
    fontWeight: '500',
    padding: '8px 14px',
    p: {
      paddingLeft: '0px',
    },
  },
});
