import React, { useState } from 'react';
import Button from '../../components/shared/Button';
import AddIcon from '@mui/icons-material/Add';
import { GenericTable } from '../../components/shared/Table/Table';
import AddTeamMemberDialog from '../../components/shared/AddTeamMemberDialog';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { updateDocument } from '../../firebase/services/updateServices';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../../utils/utlityTypes';
import { PROFILE, TEAMS } from '../../firebase/constants';
import useProfileStore from '../../stores/ProfileStore';
import {
  acceptRequest,
  deleteMember,
  rejectRequest,
} from '../../components/myStory/myTeam/MyTeamListPanel';
import RemoveTeamMemberDialog from '../../components/myStory/myTeam/RemoveTeamMemberDialog';
import { useShallow } from 'zustand/react/shallow';
import { openSnackbar } from '../../components/shared/GlobalSnackbar/GlobalSnackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '../../components/shared/Tooltip';
import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import AcceptRejectRequest from './AcceptRejectRequest';
import { getTeamInFormat } from '../../utils/helper';
import useTeamStore from '../../stores/TeamStore';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ApiRoutes } from '../../firebase/apis';
import { axiosPost } from '../../firebase/axios';

const ACCEPT_REQUEST = 'Accept';
const REJECT_REQUEST = 'Reject';

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'role',
    label: 'Role',
  },
  {
    key: 'dateAdded',
    label: 'Date Added',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'email',
    label: 'Email Id',
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
  },
];

export const TeamMemberView = ({ teamId, teamMembers }) => {
  const profile = useProfileStore((state) => state.profile);
  const [requestAction, setRequestAction] = useState({
    open: false,
    action: '',
    uid: '',
  });
  const [disableAction, setDisableAction] = useState(false);

  const [addCollaborator, setAddCollaborator] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const modifiedTeamMembers = filterUniAndTeamUniversily(
    teamMembers?.map((teamMember) => {
      return {
        uid: teamMember?.uid,
        name: teamMember?.profileName,
        role: teamMember?.role,
        dateAdded: teamMember?.dateAdded?.toDate()?.toLocaleDateString(),
        status: teamMember?.invite_status,
        email: teamMember.email,
        phoneNumber: teamMember.phoneNumber,
      };
    })
  );

  const teamStore = useTeamStore(
    useShallow((state) => ({
      isRemovingTeamMember: state.isRemovingTeamMember,
      setIsRemovingTeamMember: state.setIsRemovingTeamMember,
      setIsRemoveTeamMemberId: state.setIsRemoveTeamMemberId,
      removeTeamMemberId: state.removeTeamMemberId,
      teams: state.teams,
    }))
  );
  const onDelete = async () => {
    const updatedTeamMembers = getTeamInFormat(teamMembers); // To get teamMembers in document format
    await deleteMember(
      updatedTeamMembers,
      teamId,
      teamStore.removeTeamMemberId
    );
    teamStore.setIsRemoveTeamMemberId(null);
    openSnackbar(`The team member was removed successfully`, 'success', 5000);
  };

  const actions = [
    {
      label: (row) => {
        if (row.uid === profile?.uid) {
          return null;
        }
        if (row.status === INVITE_STATUS.REQUESTED_TO_JOIN) {
          return (
            <IconButton
              onClick={async () => {
                setRequestAction({
                  open: true,
                  action: ACCEPT_REQUEST,
                  uid: row.uid,
                });
              }}
              size="md"
            >
              <Tooltip title="Accept">
                <CheckCircleRounded className="text-alert-success" />
              </Tooltip>
            </IconButton>
          );
        }
      },
    },
    {
      label: (row) => {
        if (row.uid === profile?.uid || row.role === 'Student') {
          return null;
        }
        if (row.status === INVITE_STATUS.REQUESTED_TO_JOIN) {
          return (
            <IconButton
              onClick={async () => {
                setRequestAction({
                  open: true,
                  action: REJECT_REQUEST,
                  uid: row.uid,
                });
              }}
              size="lg"
            >
              <Tooltip title="Reject">
                <CancelRounded className="text-alert-error" />
              </Tooltip>
            </IconButton>
          );
        } else {
          return (
            <Tooltip title="Remove Team Member">
              <DeleteIcon
                onClick={async () => {
                  teamStore.setIsRemoveTeamMemberId(row.uid);
                  teamStore.setIsRemovingTeamMember(true);
                }}
              />
            </Tooltip>
          );
        }
      },
    },
  ];

  const addNewCollaborator = async (teamMember) => {
    if (teamMember.email && teamMember.role) {
      const { email, role } = teamMember;
      const invitedCollaborator = await getProfileDataByEmail(email);
      const inviteStatus = invitedCollaborator
        ? INVITE_STATUS.REGISTERED
        : INVITE_STATUS.NOT_REGISTERED;
      await updateDocument(TEAMS, 'id', teamId, 'teamMembers', [
        {
          email: email,
          role: role,
          dateAdded: new Date(),
          invite_status: inviteStatus,
          uid: invitedCollaborator ? invitedCollaborator.uid : '',
          invitedBy: profile?.uid,
        },
      ]);
      if (invitedCollaborator) {
        await updateDocument(PROFILE, 'email', email, TEAMS, [
          { teamId: teamId, status: inviteStatus },
        ]);
      }
      setAddCollaborator(false);
    }
  };
  const handleResendInvite = async (entireRow) => {
    try {
      const teamName = teamStore?.teams?.find(
        (team) => team.id === teamId
      )?.teamName;
      const payload = {
        teamId: teamId,
        newMemberEmail: entireRow.email,
        teamName,
        isRegistered:
          entireRow.status === INVITE_STATUS.JOINED ||
          entireRow.status === INVITE_STATUS.REGISTERED,
        inviterUid: profile?.uid,
      };

      const result = await axiosPost(
        ApiRoutes?.INVITE_TEAM_MEMBER_HTTP,
        payload
      );
      if (result?.data?.status === 'success') {
        openSnackbar(
          `The invite has been resent to ${entireRow.email}`,
          'success',
          5000
        );
      }
    } catch (error) {
      openSnackbar(
        `Failed to resend invite to ${entireRow.email}`,
        'error',
        5000
      );
    }
  };

  return (
    <div className="h-full w-full bg-cream-50 sm:p-4 rounded-xl border">
      <div className="flex items-center justify-between py-2 px-4">
        <div className="text-xl sm:text-2xl text-primary-black font-bold">
          My Team Members
        </div>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setAddCollaborator(true);
          }}
        >
          Add Team Member
        </Button>
      </div>
      <div className="flex overflow-y-auto">
        <GenericTable
          columns={columns}
          data={modifiedTeamMembers}
          actions={actions}
          renderCell={(row, column, entireRow) => {
            if (
              column.key === 'status' &&
              row !== INVITE_STATUS.JOINED &&
              row !== INVITE_STATUS.REQUESTED_TO_JOIN
            ) {
              return (
                <p className="flex gap-2 items-center">
                  <span>{row}</span>
                  <Button
                    variant="text"
                    size="small"
                    disabled={''}
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      handleResendInvite(entireRow);
                    }}
                  >
                    <div>Resend Invite</div>
                  </Button>
                </p>
              );
            } else {
              return row;
            }
          }}
        />
      </div>
      <RemoveTeamMemberDialog
        open={teamStore.isRemovingTeamMember}
        onCancel={() => {
          teamStore.setIsRemovingTeamMember(false);
          teamStore.setIsRemoveTeamMemberId(null);
        }}
        onContinue={async () => {
          await onDelete();
          teamStore.setIsRemovingTeamMember(false);
        }}
      />
      <AddTeamMemberDialog
        existingTeamMembers={teamMembers}
        disabled={disabled}
        setDisabled={setDisabled}
        open={addCollaborator}
        onContinue={addNewCollaborator}
        onCancel={() => {
          setDisabled(false);
          setAddCollaborator(false);
        }}
        memberData={{
          firstName: '',
          lastName: '',
          email: '',
          role: '',
        }}
      />
      <AcceptRejectRequest
        open={requestAction.open}
        text={'Do you want to accept this request?'}
        title={`${requestAction.action} Request`}
        onClose={() => setRequestAction({ open: false, action: '', uid: '' })}
        onSubmit={() => {
          const updatedTeamMembers = getTeamInFormat(teamMembers); // To get teamMembers in document format
          if (requestAction.action === ACCEPT_REQUEST) {
            acceptRequest(updatedTeamMembers, teamId, requestAction.uid);
          } else {
            rejectRequest(updatedTeamMembers, teamId, requestAction.uid);
          }
          setRequestAction({ open: false, action: '', uid: '' });
        }}
        continueText={requestAction.action}
      />
    </div>
  );
};
