import Button from '../Button';
import KeyImage from '../../../assets/svgs/Key.png';
import { StudentNotRegistered } from '../../../pages/myTeamDetails/StudentNotRegistered';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import {
  annualPlanDetails,
  monthlyPlanDetails,
} from '../../../constants/staticData';
import UnlimitedPlanCard from './UnlimitedPlanCard';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';

const UpgradePlans = ({
  subscriptionData = [],
  profileData = {},
  currentLicenseType,
  buySubscription = () => {},
  manageSubscription = () => {},
}) => {
  const fullVersionData = [
    { label: 'Activities' },
    { label: 'Courses' },
    { label: 'College Application' },
    { label: 'Honors' },
    { label: 'Essays' },
    { label: 'Tests' },
  ];

  const [alignment, setAlignment] = useState('yearly');

  const [planDetails, setPlanDetails] = useState(annualPlanDetails);

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
      if (newAlignment === 'yearly') {
        setPlanDetails(annualPlanDetails);
      } else {
        setPlanDetails(monthlyPlanDetails);
      }
    }
  };

  return (
    <div className="w-full h-full overflow-auto py-6 px-4">
      {profileData?.email ? (
        <div className="flex flex-col gap-8 items-center justify-center rounded-3xl">
          <div className="w-full flex justify-between">
            <div className="flex flex-col gap-2 text-center flex-1">
              <div className="text-2xl font-bold text-grey-900">
                License Options
              </div>
              <div className="flex flex-col text-sm font-normal text-lead-250 leading-6">
                Universily can be licensed for different lengths of time
                depending on your needs.
              </div>
            </div>
            <div>
              <div className="bg-white">
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="yearly">Yearly</ToggleButton>
                  <ToggleButton value="monthly">Monthly</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:grid sm:grid-cols-3 gap-8 w-full">
            {/* <SubscriptionCard
              buySubscription={buySubscription}
              mode={'subscription'}
              buyButtonLabel={'Select Plan'}
              duration={'monthPlan'}
              planName={'Monthly Plan'}
              subText={'Billed Monthly'}
              price={'$49'}
              priceSubText={'/month'}
            />
            <SubscriptionCard
              buySubscription={buySubscription}
              mode={'subscription'}
              buyButtonLabel={'Select Plan'}
              duration={'yearPlan'}
              planName={'Annual Plan'}
              subText={'Billed $468 Annually'}
              price={'$39'}
              priceSubText={'/month'}
            />
            <SubscriptionCard
              buySubscription={buySubscription}
              mode={'subscription'}
              buyButtonLabel={'Select Plan'}
              duration={'basicMonthPlan'}
              planName={'Basic Monthly Plan'}
              subText={'Billed Monthly'}
              price={'$19'}
              priceSubText={'/month'}
            />
            <SubscriptionCard
              buySubscription={buySubscription}
              mode={'subscription'}
              buyButtonLabel={'Select Plan'}
              duration={'basicYearPlan'}
              planName={'Basic Annual Plan'}
              subText={'Billed $179 Annually'}
              price={'$179'}
              priceSubText={'/year'}
            />
            <UnlimitedCard
              data={fullVersionData}
              profileData={profileData}
              buySubscription={buySubscription}
              mode={'payment'}
              buyButtonLabel={'Select Plan'}
              duration={'unlimitedPlan'}
              planName={'Unlimited Plan'}
              subText={'One-Time Payment $1392'}
              price={'$29'}
              priceSubText={'/month*'}
              priceAdditionalInfo={'*calculated over a full high school career'}
            /> */}
            {planDetails.map((plan, index) => (
              <SubscriptionPlanCard
                planDetails={plan}
                key={index}
                buySubscription={buySubscription}
                manageSubscription={manageSubscription}
                currentLicenseType={currentLicenseType}
              />
            ))}
          </div>
          <UnlimitedPlanCard
            buySubscription={buySubscription}
            currentLicenseType={currentLicenseType}
          />
        </div>
      ) : (
        <StudentNotRegistered />
      )}
    </div>
  );
};

export default UpgradePlans;

const SubscriptionCard = ({
  buySubscription,
  duration,
  mode,
  buyButtonLabel,
  planName,
  subText,
  price,
  priceSubText,
}) => {
  return (
    <div className="flex flex-col border p-8 bg-white shadow-1  rounded-2xl flex-1">
      <div className="flex flex-col gap-14 h-full justify-between">
        <div className="flex flex-col gap-3 pb-6 justify-between w-full border-b border-gray-400/2 items-center">
          <h2 className="font-bold text-2xl text-gray-700">{planName}</h2>
        </div>
        <div className=" flex flex-col gap-14 text-center text-2xl">
          <div className="flex flex-col gap-3">
            <h3 className="font-bold text-4xl font-archivo">{price}</h3>
            <p className="text-gray-600 font-normal text-xl font-archivo">
              {priceSubText}
            </p>
            <p className="text-gray-500 text-xs mb-[1.6rem]">{subText}</p>
          </div>
          <Button
            variant="contained"
            className="rounded-lg !py-4 !px-6 !text-base !font-bold"
            onClick={() => buySubscription(duration, mode)}
          >
            {buyButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

const UnlimitedCard = ({
  buySubscription,
  duration,
  mode,
  buyButtonLabel,
  planName,
  subText,
  price,
  priceSubText,
  priceAdditionalInfo,
}) => {
  return (
    <div className="flex flex-col border bg-white shadow-1 rounded-2xl flex-1">
      <img
        src={KeyImage}
        className="w-44 h-28 self-center"
        alt="key illustration"
      />
      <div className="p-8 pt-0">
        <div className="flex flex-col  gap-14">
          <div className="flex flex-col gap-6 text-center text-2xl">
            <div className="flex flex-col gap-3 pb-6 justify-between w-full border-b border-gray-400/2 items-center">
              <h2 className="font-bold text-2xl text-gray-700">{planName}</h2>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="font-bold text-4xl font-archivo">{price}</h3>
              <p className="text-gray-600 font-normal text-xl font-archivo">
                {priceSubText}
              </p>
              <p className="text-gray-500 text-xs">{subText}</p>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-gray-500 text-xs text-center">
              {priceAdditionalInfo}
            </p>
            <Button
              variant="contained"
              className="rounded-lg !py-4 !px-6 !text-base !font-bold"
              onClick={() => buySubscription(duration, mode)}
            >
              {buyButtonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
