import create from 'zustand';

const useThemeStore = create((set) => ({
  isMobile: false,
  setIsMobile: (isMobile) => set(() => ({ isMobile })),
  isMobileViewHeader: false,
  setIsMobileViewHeader: (isMobileViewHeader) =>
    set(() => ({ isMobileViewHeader })),
  isHeaderTitle: null,
  setIsHeaderTitle: (isHeaderTitle) => set(() => ({ isHeaderTitle })),
}));

export default useThemeStore;
