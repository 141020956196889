import { styled } from '@mui/system';
import { Drawer } from '@mui/material';

export const ChatBoxCustom = styled('div')(({ theme }) => ({
  padding: '20px',
  height: '100%',
  h4: {
    textAlign: 'center',
    margin: '0px',
    fontWeight: '400',
    fontSize: '20px',
    color: '#2C303C',
  },
}));

export const CustomDrawer = styled(Drawer)({
  backgroundColor: 'transparent',
  boxShadow: 'none',

  '.MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '.MuiDrawer-paper': {
    backgroundColor: '#FDFDFD',
    border: '1px solid #EAEAEB',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    height: 'calc(100% - 84px)',
    top: 'auto',
    bottom: '0',

    // borderRadius: '8px 0px 0px 8px',
  },
});

export const ChatBlockInner = styled('div')(({ theme }) => ({
  height: 'calc(100% - 5px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
