import { styled } from '@mui/system';
import { Modal, Box, IconButton } from '@mui/material';

export const ModalCustomBlock = styled(Modal)({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(44, 48, 60, 0.5)',
  },
});
export const BoxCustomModal = styled(Box)({
  border: 'none !important',
  background: 'none !important',
  boxShadow: 'none !important',
  width: '475px !important',
  padding: '0px !important',
});
export const BoxCustomModalInner = styled(Box)({
  background: '#FCFCFD',
  boxShadow: '0px 64px 64px -48px rgba(31, 47, 70, 0.12)',
  borderRadius: '16px',
});
export const CloseModalIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-20px',
  right: '-18px',
}));
export const IconButtonClose = styled(IconButton)({
  backgroundColor: '#FFFFFF !important',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: '50px',
  width: '40px',
  height: '40px',
});
export const BlockModalAdd = styled('div')(({ theme }) => ({}));
export const BlockModalAddTitle = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #EAEAEB',
  padding: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  h2: {
    fontSize: '34px',
    color: '#2C303C',
    fontWeight: '500',
  },
  p: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '16px',
    fontWeight: '500',
  },
}));

export const BLockModalDetails = styled('div')(({ theme }) => ({
  padding: '16px',
}));
export const BLockModalImg = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  h3: {
    fontSize: '20px',
    fontWeight: '400',
    paddingLeft: '24px',
    color: '#2C303C',
  },
}));
export const BLockModalImgInner = styled('div')(({ theme }) => ({
  width: '54px',
  height: '54px',
  backgroundColor: '#DEEEF4',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.yellow-bg-icon': {
    backgroundColor: '#EFE2CB',
  },
  '&.gray-bg-black': {
    backgroundColor: '#E3E3E3',
  },
}));
export const IconButtonAdd = styled(IconButton)({
  borderRadius: '8px',
  marginBottom: '8px',
  width: '100%',
  display: 'flex',
  padding: '8px 20px 8px 8px',
  justifyContent: 'space-between',
});
// export const LeftTopBlock = styled('div')(({ theme }) => ({}));
