import { Divider } from '@mui/material';
import { ReactComponent as LogoImage } from '../../../assets/svgs/Logo.svg';
import { PremiumVersionCard } from './PremiumVersionCard';
import Button from '../../shared/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TeamMemberCard } from './TeamMemberCard';
import { useState } from 'react';
import { ExpandLess } from '@mui/icons-material';
import useProfileStore from '../../../stores/ProfileStore';
import config from '../../../utils/urlConfig';
import SuccessSnackbar from '../../shared/SuccessSnackbar';
import { axiosPost } from '../../../firebase/axios';
import { ApiRoutes } from '../../../firebase/apis';
import UnlimitedPlanCard from '../../shared/upgrade/UnlimitedPlanCard';
import SubscriptionPlanCard from '../../shared/upgrade/SubscriptionPlanCard';

export const SubscriptionPage = ({
  manageSubscription = () => {},
  licenseData,
  referralList = [],
  currentPlan,
  planDetails,
  currentLicenseType,
}) => {
  // States
  const [isReferralCardVisible, setIsReferralCardVisible] = useState(true);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    message: '',
  });
  const [isSending, setIsSending] = useState(false);

  // Hooks
  const profileStore = useProfileStore();

  // Handlers
  const onResendInvite = async (referral) => {
    try {
      setIsSending(true);
      const { firstName, lastName, uid } = profileStore.profile;
      let params = {};
      params = {
        reviewerEmail: referral.email,
        revieeeName: firstName + ' ' + lastName,
        templateName: 'user_invite',
        inviteLink: `${config.getSiteBaseUrl}/signUp`,
        firstName: referral?.firstName || '',
        lastName: referral?.lastName || '',
      };
      const attributes = {
        senderId: uid,
        senderFirstName: firstName,
        senderLastName: lastName,
        receiverEmail: referral.email,
        receiverFirstName: referral.firstName,
        receiverLastName: referral.lastName,
      };
      let result;
      result = await axiosPost(ApiRoutes?.REFERRAL, attributes);
      setIsSending(false);
      if (result?.data) {
        setSuccessSnackbarOpen({
          open: true,
          message: `Invite sent to ${referral.firstName} ${referral.lastName}`,
        });
      } else {
        setSuccessSnackbarOpen({
          open: true,
          message: `Something went wrong!`,
        });
      }
    } catch (error) {
      setSuccessSnackbarOpen({
        open: true,
        message: `Something went wrong!`,
      });
    }
  };

  return (
    <div className="flex flex-col py-10 gap-6 px-6 w-full sm:h-full sm:overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-8 w-full">
          <LogoImage />
          {/* <PremiumVersionCard
            manageSubscription={manageSubscription}
            amount={licenseData?.amount}
            interval={licenseData?.licenseType}
            licenseEndDate={licenseData?.licenseEndDate}
            subscriptionStatus={licenseData?.subscriptionStatus}
          /> */}
          {currentPlan === 'unlimited' ? (
            <UnlimitedPlanCard
              manageSubscription={manageSubscription}
              currentLicenseType={currentLicenseType}
            />
          ) : (
            <div className="w-1/3">
              <SubscriptionPlanCard
                planDetails={planDetails}
                buySubscription={() => ({})}
                manageSubscription={manageSubscription}
                currentLicenseType={currentLicenseType}
              />
            </div>
          )}
          <Divider
            className="w-full opacity-20"
            sx={{
              borderColor: '#BABAAE',
            }}
          />
        </div>
      </div>
      <div className="flex flex-1 h-full overflow-hidden">
        <div
          className={`grid grid-cols-1 sm:grid-cols-3 flex flex-1 gap-4 transition-all duration-300 overflow-y-scroll w-full ${
            !isReferralCardVisible ? 'h-0' : ''
          }`}
        >
          <SuccessSnackbar
            message={successSnackbarOpen.message}
            open={successSnackbarOpen.open}
            autoHideDuration={6000}
            onClose={() => {
              setSuccessSnackbarOpen({
                open: false,
                message: '',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
