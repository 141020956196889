/**
 *
 * @param {string} licenseType
 * @returns
 */
export const getUserAccess = (licenseType) => {
  const accessRules = {
    freemium: {
      niches: 2,
      activities: 2,
      honors: 2,
      courses: 2,
      tests: 2,
      gpa: 2,
      essays: 2,
      colleges: 2,
    },
    basic_monthly: {
      niches: Infinity,
      activities: Infinity,
      honors: Infinity,
      courses: Infinity,
      tests: Infinity,
      gpa: Infinity,
      essays: 2,
      colleges: 2,
    },
    basic_yearly: {
      niches: Infinity,
      activities: Infinity,
      honors: Infinity,
      courses: Infinity,
      tests: Infinity,
      gpa: Infinity,
      essays: 2,
      colleges: 2,
    },
    premium_monthly: {
      niches: Infinity,
      activities: Infinity,
      honors: Infinity,
      courses: Infinity,
      tests: Infinity,
      gpa: Infinity,
      essays: Infinity,
      colleges: Infinity,
    },
    premium_yearly: {
      niches: Infinity,
      activities: Infinity,
      honors: Infinity,
      courses: Infinity,
      tests: Infinity,
      gpa: Infinity,
      essays: Infinity,
      colleges: Infinity,
    },
    unlimited: {
      niches: Infinity,
      activities: Infinity,
      honors: Infinity,
      courses: Infinity,
      tests: Infinity,
      gpa: Infinity,
      essays: Infinity,
      colleges: Infinity,
    },
  };
  return accessRules[licenseType] || accessRules.freemium; // default to freemium if licenseType is undefined
};
