import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Chip } from '@mui/material';
import useTeamStore from '../../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';

const SubscriptionPlanCard = ({
  planDetails = {},
  currentLicenseType,
  buySubscription = () => {},
  manageSubscription = () => {},
}) => {
  const licenseType = useTeamStore(
    useShallow(
      (state) =>
        currentLicenseType || state.selectedTeam?.licenseData?.licenseType
    )
  );

  const [planButtonText, setPlanButtonText] = useState('Select');

  const [isCurrentPlan, setIsCurrentPlan] = useState(false);

  useEffect(() => {
    if (licenseType) {
      setPlanButtonText('Upgrade');
      setIsCurrentPlan(planDetails.plan === licenseType);
    } else {
      setPlanButtonText('Select');
      setIsCurrentPlan(planDetails.plan === 'freemium');
    }
  }, [licenseType, planDetails.plan]);

  return (
    <div
      className={`border-2 rounded-lg flex flex-col justify-between gap-6 px-4 py-8 font-archivo bg-white shadow-1 sm:h-full sm:overflow-auto ${
        isCurrentPlan ? 'border-[#30b28b]' : 'border-gray-400'
      }`}
    >
      <div className="flex flex-col gap-3 flex-1">
        <div className="flex items-center justify-between w-full">
          <div aria-label="title" className="text-xl font-bold">
            {planDetails?.title}
          </div>
          {isCurrentPlan && (
            <Chip
              label="Current Plan"
              sx={{ backgroundColor: '#30b28b', color: 'white' }}
            />
          )}
        </div>
        <div className="flex items-center justify-start gap-2 w-3/5">
          <div className="text-[56px] font-bold">{planDetails?.price}</div>
          <div className="text-lg"> {planDetails?.priceSubText}</div>
        </div>
        <div className="text-[#838489] text-lg font-medium w-full text-center -mt-6">
          {planDetails?.planText}
        </div>
        <div className="flex flex-col w-full gap-2">
          {planDetails?.planFeatures?.map((feature, index) => (
            <div key={index} className="flex items-center gap-2">
              <CheckCircleIcon fontSize="small" sx={{ color: '#30b28b' }} />
              <div
                className={`text-sm  ${
                  !index && planDetails.plan !== 'freemium'
                    ? 'text-black font-bold'
                    : 'text-[#838489]'
                }`}
              >
                {feature}
              </div>
            </div>
          ))}
        </div>
      </div>
      {!isCurrentPlan && (
        <Button
          variant="contained"
          className="rounded-lg !py-4 !px-6 !text-base !font-bold"
          onClick={() =>
            buySubscription(planDetails.duration, planDetails.mode)
          }
        >
          {planButtonText}
        </Button>
      )}
      {isCurrentPlan && licenseType && (
        <Button
          variant="outlined"
          className="rounded-lg !py-4 !px-6 !text-base !font-bold"
          onClick={manageSubscription}
        >
          Modify Plan
        </Button>
      )}
    </div>
  );
};

export default SubscriptionPlanCard;
