import { GlobalStyles } from '@mui/material';

const styles = {
  body: {
    backgroundColor: '#fff',
    padding: '0px',
    margin: '0px',
    fontFamily: "'Roboto', sans-serif",
  },
  h1: {
    margin: '0px',
  },
  h2: {
    margin: '0px',
  },
  h3: {
    margin: '0px',
  },
  h4: {
    margin: '0px',
  },
  h5: {
    margin: '0px',
  },
  h6: {
    margin: '0px',
  },
  p: {
    margin: '0px',
  },
};

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const CustomGlobalStyles = () => {
  return <GlobalStyles styles={styles} />;
};
