import { Accordion as ACCORDION, AccordionDetails } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';

const StyledAccordion = styled(ACCORDION)(({ isMobile = false }) => ({
  background: '#FCFCF9',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  border: '1px solid #E2E2DB',
  boxShadow: 'none',
  padding: isMobile ? '16px' : '24px',
  borderRadius: '16px !important',
  margin: '0px !important',
  '::before': {
    background: 'none !important',
  },
  '.MuiButtonBase-root': {
    minHeight: 'auto !important',
    gap: '8px',
    '& .MuiAccordionSummary-root': {
      padding: '0px !important',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      order: 1,
      margin: 0,
      '& .Mui-expanded': {
        margin: '0px !important',
      },
      p: {
        color: '#22252D',
        fontSize: '20px',
        fontWeight: '500',
      },
    },
  },
  '.MuiCollapse-root': {
    '.MuiAccordionDetails-root': {
      padding: 0,
    },
  },
}));

export const Accordion = ({
  header = {},
  children = {},
  isMobile = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledAccordion
      expanded={isOpen}
      isMobile={isMobile}
      onClick={() => setIsOpen(!isOpen)}
      {...props}
    >
      {header}
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
