import { ThemeProvider, styled } from '@mui/system';
import { Button as BUTTON } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const StyledButton = styled(BUTTON)(() => ({
  textTransform: 'none',
  flexShrink: '0 !important',
  borderRadius: '8px !important',
  minWidth: 'fit-content !important',
  gap: '0px !important',
}));

const theme = createTheme({
  palette: {
    link: {
      main: '#616675',
    },
    buttonLink: {
      main: '#1958CD',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'buttonLink' && {
              ':hover': {
                background: 'none',
                textDecoration: 'underline !important',
                textUnderlineOffset: '2px',
              },
            }),
          ...(ownerState.color === 'link' && {
            ':hover': {
              color: '#494E5B',
              background: 'none',
              textDecoration: 'underline',
              textUnderlineOffset: '2px',
              underlineOffset: 2,
              textDecorationColor: '#494E5B',
            },
          }),
        }),
      },
    },
  },
});

const Button = ({
  children,
  // overriden default props
  variant = 'contained',
  disabled = false,
  disableElevation = true,
  // all other props
  ...props
}) => {
  const defaultProps = {
    variant,
    disableElevation,
  };
  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        {...defaultProps}
        {...props}
        disabled={disabled}
        className={props.className}
      >
        {children}
      </StyledButton>
    </ThemeProvider>
  );
};

export default Button;
