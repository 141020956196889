import { Divider, IconButton, Radio } from '@mui/material';
import Dialog from '../shared/Dialog';
import { useEffect, useState } from 'react';
import { essayTypeForPlatform, googleLogin } from '../../constants/staticData';
import useEssayStore from '../../stores/EssayStore';
import {
  addStoryItemTypes,
  applicationTags,
  essayCategory,
} from '../../constants/other';
import Tooltip from '../shared/Tooltip';
import { Add } from '@mui/icons-material';
import SelectTeamMembers from '../shared/SelectTeamMembers';
import { useGoogleDocs } from '../../hooks';
import { googleDriveFolders } from '../../constants/keywords';
import { addStoryItem } from '../../firebase/services/addServices';
import useProfileStore from '../../stores/ProfileStore';
import useTemplateStore from '../../stores/TemplateStore';
import { ESSAY_TYPE_APPLICATION } from '../../firebase/constants';
import useApplicationStore from '../../stores/ApplicationStore';
import useStudentStore from '../../stores/StudentStore';
import useTeamStore from '../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../../utils/utlityTypes';

const AddEssayApplicationDialog = ({
  collegeName = '',
  reviewers = [],
  selectedEssay = {},
  isParent = false,
  collegeId = '',
  isEssayPage = false,
  isAdding = false,
  onCancel = () => {},
  whereSubmittedToCreateNewEssay = '',
  whereSubmitted = '',
  ...args
}) => {
  const [copyOption, setCopyOption] = useState('coreEssay');
  const [disableActionBtn, setDisableActionBtn] = useState(false);
  const { essays, setIsAddingEssayForApplication } = useEssayStore();
  const { setIsAddingEssay } = useApplicationStore();
  const [coreEssays, setCoreEssays] = useState([]);

  const teamStore = useTeamStore();

  const [selectTemplate, setSelectTemplate] = useState({});
  const [selectedCoreEssay, setSelectedCoreEssay] = useState({});
  const googleDocs = useGoogleDocs();

  const { profileLogin } = useProfileStore();
  const { templateData } = useTemplateStore();
  const studentStore = useStudentStore();

  const userProfile = useProfileStore((state) => state.profile);

  const selectedTeam = useTeamStore((state) => state.selectedTeam);

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== selectedTeam?.studentEmail)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  const [selectedTeamMembers, setSelectedTeamMembers] = useState(() =>
    filterUniAndTeamUniversily(collaborators)
  );

  const getDocDataInFormat = async (id) => {
    const googleDocData = await googleDocs.getGoogleDoc(id, isParent);
    return {
      charCount: googleDocData.charCount,
      content: googleDocData.content,
      wordCount: googleDocData.wordCount,
    };
  };

  useEffect(() => {
    if (copyOption === 'selectTemplate') {
      setSelectedCoreEssay({});
    } else {
      setSelectTemplate({});
    }
  }, [copyOption]);

  useEffect(() => {
    if (essays) {
      setCoreEssays(
        essays.filter((essay) => essay.type === essayTypeForPlatform.coreEssay)
      );
    }
  }, [essays]);

  const onAddEssay = async () => {
    // NOTE: for essayReferenceId: if user is adding essay from college platform then assign selectedEssay?.essayId else assign selectedEssay?.id
    let essayName = '';
    const teamMembersToShare =
      selectedTeamMembers.map((teamMember) => teamMember?.email) || [];
    if (
      selectedEssay?.essayType === 'Common Essay' ||
      whereSubmittedToCreateNewEssay
    ) {
      const platFormName =
        applicationTags?.find(
          (tag) => tag?.applicationPlatform === selectedEssay?.whereSubmitted
        )?.label || whereSubmittedToCreateNewEssay;
      essayName =
        platFormName +
        '-' +
        (selectedEssay?.essayName || selectedEssay?.essayExternalName);
    } else {
      essayName = collegeName + '-' + selectedEssay?.essayName;
    }

    if (profileLogin?.loggedInType === googleLogin) {
      console.log('if googleLogin');
      if (copyOption === 'coreEssay') {
        console.log('if coreEssay');
        const docId = selectedCoreEssay?.docs?.split('/')[5];
        const docName = essayName;

        if (
          selectedEssay?.essayType === 'Common Essay' ||
          whereSubmittedToCreateNewEssay
        ) {
          console.log('if common essay');
          if (!isParent) {
            const copyDoc = await googleDocs.createCopyOfGoogleDoc(
              docId,
              googleDriveFolders.ESSAY,
              docName
            );
            await googleDocs.giveTeamMembersAccessToDocs(
              copyDoc?.id,
              teamMembersToShare
            );
            const googleDocData = await getDocDataInFormat(copyDoc?.id);
            await addStoryItem(
              {
                essayCategory: selectedCoreEssay?.essayCategory,
                copiedFromId: selectedCoreEssay.id || '',
                essayName: essayName,
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: userProfile?.email,
                docs: copyDoc?.webViewLink,
                essaySubType: 'application',
                type: ESSAY_TYPE_APPLICATION,
                applicationName: selectedEssay?.whereSubmitted
                  ? selectedEssay?.whereSubmitted
                  : whereSubmitted,
                docInformation: {
                  charCount: googleDocData?.charCount,
                  content: googleDocData?.content,
                  wordCount: googleDocData?.wordCount,
                },
              },
              addStoryItemTypes.ESSAY
            );
          } else {
            const temp = {
              applicationName: selectedEssay?.whereSubmitted
                ? selectedEssay?.whereSubmitted
                : whereSubmitted,
              essayCategory: selectedCoreEssay?.essayCategory,
              copiedFromId: selectedCoreEssay.id || '',
              collegeId: collegeId,
              essayName: essayName,
              essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
              sharedWith:
                selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
              essaySubType: 'application',
              type: ESSAY_TYPE_APPLICATION,
              createdBy: studentStore?.selectedStudent?.uid,
              isCopied: false,
              owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
            };
            await addStoryItem(
              {
                applicationName: selectedEssay?.whereSubmitted
                  ? selectedEssay?.whereSubmitted
                  : whereSubmitted,
                essayCategory: selectedCoreEssay?.essayCategory,
                copiedFromId: selectedCoreEssay.id || '',
                collegeId: collegeId,
                essayName: essayName,
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                essaySubType: 'application',
                type: ESSAY_TYPE_APPLICATION,
                createdBy: studentStore?.selectedStudent?.uid,
                isCopied: false,
                owner: isParent
                  ? selectedTeam?.studentEmail
                  : userProfile?.email,
              },
              addStoryItemTypes.ESSAY
            );
          }
        } else {
          console.log('if not common essay');
          const docId = selectedCoreEssay?.docs?.split('/')[5];
          const docName = essayName;
          if (!isParent) {
            console.log('if not parent');
            const copyDoc = await googleDocs.createCopyOfGoogleDoc(
              docId,
              googleDriveFolders.ESSAY,
              docName
            );
            await googleDocs.giveTeamMembersAccessToDocs(
              copyDoc?.id,
              teamMembersToShare
            );
            const googleDocData = await getDocDataInFormat(copyDoc?.id);
            await addStoryItem(
              {
                collegeId: collegeId,
                essayCategory: selectedCoreEssay?.essayCategory,
                copiedFromId: selectedCoreEssay.id || '',
                essayName: essayName,
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: userProfile?.email,
                docs: copyDoc?.webViewLink,
                essaySubType: 'college',
                type: ESSAY_TYPE_APPLICATION,
                docInformation: {
                  charCount: googleDocData?.charCount,
                  content: googleDocData?.content,
                  wordCount: googleDocData?.wordCount,
                },
              },
              addStoryItemTypes.ESSAY
            );
          } else {
            console.log('if parent');
            await addStoryItem(
              {
                collegeId: collegeId,
                essayCategory: selectedCoreEssay?.essayCategory,
                copiedFromId: selectedCoreEssay.id || '',
                essayName: essayName,
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: isParent
                  ? selectedTeam?.studentEmail
                  : userProfile?.email,
                essaySubType: 'college',
                type: ESSAY_TYPE_APPLICATION,
                createdBy: studentStore?.selectedStudent?.uid,
                isCopied: false,
              },
              addStoryItemTypes.ESSAY
            );
          }
        }
      } else {
        console.log('if not coreEssay');

        if (
          selectedEssay?.essayType === 'Common Essay' ||
          whereSubmittedToCreateNewEssay
        ) {
          console.log('if common essay');
          if (!isParent) {
            let template = await googleDocs.googleDocData(
              templateData || '',
              selectTemplate?.tag || ''
            );
            const essayDoc = await googleDocs.createGoogleDoc(
              essayName,
              googleDriveFolders.ESSAY,
              template
            );
            await googleDocs.giveTeamMembersAccessToDocs(
              essayDoc?.id,
              teamMembersToShare
            );
            const googleDocData = await getDocDataInFormat(essayDoc?.id);

            await addStoryItem(
              {
                essayName,
                docs: essayDoc?.webViewLink,
                type: ESSAY_TYPE_APPLICATION,
                docInformation: {
                  charCount: googleDocData?.charCount,
                  content: googleDocData?.content,
                  wordCount: googleDocData?.wordCount,
                },
                essaySubType: 'application',
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                essayCategory: selectTemplate?.tag || '',
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: userProfile?.email,
                applicationName: selectedEssay?.whereSubmitted
                  ? selectedEssay?.whereSubmitted
                  : whereSubmitted,
              },
              addStoryItemTypes.ESSAY
            );
          } else {
            await addStoryItem(
              {
                essayName,
                type: ESSAY_TYPE_APPLICATION,
                essaySubType: 'application',
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                essayCategory: selectTemplate?.tag || '',
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: isParent
                  ? selectedTeam?.studentEmail
                  : userProfile?.email,
                applicationName: selectedEssay?.whereSubmitted
                  ? selectedEssay?.whereSubmitted
                  : whereSubmitted,
                createdBy: userProfile?.email,
              },
              addStoryItemTypes.ESSAY
            );
          }
        } else {
          console.log('if not common essay');
          if (!isParent) {
            let template = await googleDocs.googleDocData(
              templateData || '',
              selectTemplate?.tag || ''
            );
            const essayDoc = await googleDocs.createGoogleDoc(
              essayName,
              googleDriveFolders.ESSAY,
              template
            );
            await googleDocs.giveTeamMembersAccessToDocs(
              essayDoc?.id,
              teamMembersToShare
            );
            const googleDocData = await getDocDataInFormat(essayDoc?.id);
            await addStoryItem(
              {
                essayName,
                docs: essayDoc?.webViewLink,
                type: ESSAY_TYPE_APPLICATION,
                docInformation: {
                  charCount: googleDocData?.charCount,
                  content: googleDocData?.content,
                  wordCount: googleDocData?.wordCount,
                },
                essaySubType: 'college',
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                essayCategory: selectTemplate?.tag || '',
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: userProfile?.email,
                collegeId: collegeId,
              },
              addStoryItemTypes.ESSAY
            );
          } else {
            await addStoryItem(
              {
                essayName,
                type: ESSAY_TYPE_APPLICATION,
                essaySubType: 'college',
                essayReferenceId: selectedEssay?.essayId || selectedEssay?.id,
                essayCategory: selectTemplate?.tag || '',
                sharedWith:
                  selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
                owner: isParent
                  ? selectedTeam?.studentEmail
                  : userProfile?.email,
                collegeId: collegeId,
                createdBy: studentStore?.selectedStudent?.uid,
              },
              addStoryItemTypes.ESSAY
            );
          }
        }
      }
    } else {
      console.log('if not googleLogin');
      await addStoryItem(
        {
          essayName,
          type: ESSAY_TYPE_APPLICATION,
          essayCategory: selectTemplate?.tag || '',
          sharedWith:
            selectedTeamMembers?.map((teamMember) => teamMember.id) || [],
          owner: userProfile?.email,
          applicationName: selectedEssay?.whereSubmitted,
        },
        addStoryItemTypes.ESSAY
      );
    }
    setIsAddingEssayForApplication(false);
    setIsAddingEssay(false);
    setSelectedTeamMembers([]);
    setSelectedCoreEssay({});
    setSelectTemplate({});
  };

  return (
    <Dialog
      title="Add Application Essay"
      continueText="Add"
      onContinue={async () => {
        setDisableActionBtn(true);
        await onAddEssay();
        setDisableActionBtn(false);
      }}
      onCancel={() => {
        onCancel();
        setDisableActionBtn(false);
      }}
      isLoading={disableActionBtn}
      disabled={disableActionBtn}
      {...args}
    >
      <div className="flex flex-col h-full gap-6">
        <div className="bg-grey-50 flex gap-4 text-grey-500 text-sm rounded-lg items-center p-3">
          <div>{collegeName}</div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: '#D6D6D8',
            }}
            variant="middle"
          />
          <div>
            {selectedEssay?.essayName || selectedEssay?.essayExternalName}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex text-xs text-lead-500">
            Copy from a Core Essay, or Select a Template
          </div>
          <div className="flex font-medium items-center gap-8">
            <div className="flex items-center gap-3">
              <Radio
                name="radio-buttons"
                inputProps={{ 'aria-label': 'Copy from written Core Essays' }}
                sx={{
                  padding: 0,
                }}
                checked={copyOption === essayTypeForPlatform.coreEssay}
                onChange={() => setCopyOption(essayTypeForPlatform.coreEssay)}
              />
              <div>Copy from written Core Essays</div>
            </div>
            <div className="flex items-center gap-3">
              <Radio
                name="radio-buttons"
                inputProps={{ 'aria-label': 'Select a Template' }}
                checked={copyOption === 'selectTemplate'}
                onChange={() => setCopyOption('selectTemplate')}
                sx={{
                  padding: 0,
                }}
              />
              <div>Select a Template</div>
            </div>
          </div>
        </div>
        {copyOption === essayTypeForPlatform.coreEssay && (
          <div className="flex flex-1 gap-4 text-sm h-80">
            <div className="flex flex-col gap-2">
              <div>Select Essay Name</div>
              <div className="flex flex-col border border-grey-500/23 rounded-lg h-80 w-72 overflow-y-auto p-2">
                {coreEssays?.map((essay) => (
                  <div
                    className={`${
                      selectedCoreEssay === essay && 'bg-alert-info rounded-lg'
                    } flex gap-3 rounded-lg cursor-pointer p-4 hover:bg-cream-50 hover:rounded-lg`}
                    onClick={() => setSelectedCoreEssay(essay)}
                  >
                    <IconButton>
                      <Radio
                        checked={selectedCoreEssay === essay}
                        name="radio-buttons"
                        onChange={() => setSelectedCoreEssay(essay)}
                        sx={{
                          padding: 0,
                        }}
                      />
                    </IconButton>
                    <div className="flex flex-col gap-1">
                      <div className="text-grey-500 word-break">
                        {essay.essayName}
                      </div>
                      <div className="text-xs text-grey-300">
                        ({essay?.docInformation?.wordCount} words)
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-2">
              <div>Essay Preview</div>
              <div className="flex flex-col border border-grey-500/23 text-sm text-[#595959] overflow-y-scroll p-6 rounded-lg h-80">
                {selectedCoreEssay &&
                  selectedCoreEssay?.docInformation?.content}
              </div>
            </div>
          </div>
        )}
        {copyOption === 'selectTemplate' && (
          <div>
            <div className="grid grid-cols-3 gap-4">
              {essayCategory?.map((essayType, index) => (
                <div
                  key={index}
                  className={`${
                    selectTemplate === essayType
                      ? 'bg-brand-blue-500/10'
                      : 'border-lead-900/23'
                  } cursor-pointer border rounded-lg text-grey-800 font-medium flex bg-white p-6 hover:bg-brand-blue-500/10`}
                  onClick={() => {
                    setSelectTemplate(essayType);
                  }}
                >
                  {essayType.name}
                </div>
              ))}
              <Tooltip
                title="We recommend you to use the Core Essays as a 
                reference to copy or any of the templates to fast 
              track your Essay writing. However you may start 
                with a blank page as well."
              >
                <div
                  className={`${
                    selectTemplate === 'blankTemplate'
                      ? 'bg-brand-blue-500/10'
                      : 'border-lead-900/23'
                  }
                    cursor-pointer border border-dashed rounded-lg text-grey-800 font-medium flex bg-white p-6 hover:bg-brand-blue-500/10`}
                  onClick={() => setSelectTemplate('blankTemplate')}
                >
                  <Add
                    sx={{
                      color: '#383838',
                      marginRight: '18px',
                    }}
                  />
                  Start from a Blank Page
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        <SelectTeamMembers
          label="Select Team Member (optional)"
          multiple={true}
          stTitle="Select your Select your Existing Team Members"
          options={filterUniAndTeamUniversily(collaborators)}
          value={selectedTeamMembers}
          tooltipTitle="You can select or add Team Members with whom you wish to collaborate"
          onChange={(e, teamMembers) => setSelectedTeamMembers(teamMembers)}
        />
      </div>
    </Dialog>
  );
};

export default AddEssayApplicationDialog;
