import React from 'react';
import GroupAvatarLineItem from './GroupAvatarLineItem';

export default function GroupAvatar({
    participants,
    size = 42,
    className = '',
}) {
    const displayParticipants = participants.slice(0, 4);

    const getGridClass = (total) => {
        switch (total) {
        case 1:
            return 'grid-cols-1';
        case 2:
            return 'grid-cols-2';
        case 3:
        case 4:
            return 'grid-cols-2';
        default:
            return 'grid-cols-2';
        }
    };

    return (
        <div
        className={`relative rounded-full overflow-hidden bg-gray-200 ${className}`}
        style={{ width: size, height: size }}
        >
        <div
            className={`grid ${getGridClass(
            displayParticipants.length
            )} w-full h-full gap-[2px]`}
        >
            {displayParticipants.map((participant, index) => (
                <GroupAvatarLineItem participant={participant} index={index} totalMembers={displayParticipants.length} size={size}/>
            ))}
        </div>
        </div>
    );
}
