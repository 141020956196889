import { styled } from '@mui/system';
import { List, ListItem, ListItemButton, Menu } from '@mui/material';

export const RightTopBLock = styled('div')(({ theme }) => ({
  position: 'relative',
  button: {
    padding: '7px 13px',
    borderRadius: '8px',
    textTransform: 'none',
  },
  p: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#fff',
    padding: '5px 10px',
    borderRight: '1px solid #336AD1',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '14px',
      padding: '5px 7px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 3px',
    },
  },
  svg: {
    width: '22px',
    [theme.breakpoints.down('md')]: {
      width: '18px',
    },
  },
}));

export const ListDropDown = styled(List)({
  background: '#FFFFFF',
  border: '1px solid #EAEAEB',
  boxShadow: '0px 20px 80px rgba(44, 48, 60, 0.11)',
  borderRadius: '8px',
  marginTop: '8px',
  position: 'absolute',
  width: '100%',
  top: '41px',
  zIndex: '9',
});

export const ListItemDropDown = styled(ListItem)({});

export const ListItemButtonDropDown = styled(ListItemButton)({
  '.MuiListItemText-root': {
    margin: '0px',
    span: {
      color: '#4C576B',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
});
export const MenuCustomBlock = styled(Menu)({
  '.MuiMenu-paper': {
    background: '#FFFFFF',
    border: '1px solid #EAEAEB',
    boxShadow: '0px 20px 80px rgba(44, 48, 60, 0.11)',
    borderRadius: '8px',
    width: '158px',
    marginTop: '10px',
    li: {
      fontSize: '14px',
      color: '#22252D',
      fontWeight: '400',
    },
  },
});
