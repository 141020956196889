import { useEffect } from 'react';
import { SITE_TITLE } from '../../constants/other';

const PageSiteTitle = ({ title = SITE_TITLE }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

export default PageSiteTitle;
