import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { ApiRoutes } from '../firebase/apis';
import config from '../utils/urlConfig';

import {
  getDecryptedLocalData,
  getLocalUser,
  setEncryptedLocalData,
} from '../utils/localStorage';
import { auth } from '../firebase/config';
import { getUserData } from '../firebase/services/auth';
import { localKeys, secretKeys } from '../constants/localStorage';
import { handleTokenExpired } from '../utils/refreshToken';
import { getProfileLogInData } from '../firebase/services/user';
import { apiHandler, axiosPost } from '../firebase/axios';
import { logInProviders } from '../constants/staticData';
import useProfileStore from '../stores/ProfileStore';
import { editProfile } from '../firebase/services/updateServices';
import { userTypeKeywords } from '../constants/keywords';

const useAuth = () => {
  const [user, setUser] = useState(() => getLocalUser(localKeys.AUTH));
  const [isLoading, setIsLoading] = useState(true);
  const profile = useProfileStore((state) => state.profile);
  const setProfile = useProfileStore((state) => state.setProfile);
  const setIsParent = useProfileStore((state) => state.setIsParent);

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (authUser) => {
      if (authUser && authUser?.emailVerified) {
        fetchData(authUser?.email, authUser);
      } else {
        localStorage.removeItem(localKeys?.AUTH);
        setUser(null);
        setProfile(null);
        setIsLoading(false);
      }
    });

    return () => listener();
    //eslint-disable-next-line
  }, []);

  const fetchData = async (email, authUser) => {
    const res = await getUserData(email);
    if (res) {
      const { uid, id, rt } = res;
      const loginRes = await getProfileLogInData(uid);
      if (loginRes) {
        const { loggedInType } = loginRes;
        if (loggedInType === logInProviders.GOOGLE) {
          const googleAuth = await getDecryptedLocalData(
            localKeys.GOOGLE_OAUTH_DATA,
            secretKeys.GOOGLE_OAUTH_DATA
          );
          if (googleAuth) {
            const { expiry_date, refresh_token } = googleAuth;
            tokenExpireHandler(expiry_date, refresh_token, id);
          } else if (rt) {
            expireTokenCallback(rt, id);
          }
        }
      }
      setEncryptedLocalData(
        { ...authUser, ...res },
        localKeys?.AUTH,
        secretKeys?.AUTH
      );
      setUser({ ...authUser, ...res });
      if (profile?.uid !== res?.uid) {
        setProfile(res);
      }
      setIsParent(
        res.profileType !== userTypeKeywords.STUDENT &&
          res.profileType !== userTypeKeywords.ADMINISTRATOR
      );
      setIsLoading(false);
    } else {
      localStorage.removeItem(localKeys?.AUTH);
      setUser(null);
      setProfile(null);
      setIsLoading(false);
    }
  };

  const tokenExpireHandler = (expiry_date, refresh_token, userDocId = '') => {
    handleTokenExpired(expiry_date, () =>
      expireTokenCallback(refresh_token, userDocId)
    );
  };

  const expireTokenCallback = async (refresh_token, userDocId = '') => {
    try {
      const res = await axiosPost(ApiRoutes.GOOGLE_AUTH, {
        action: 'tokenRefresh',
        refreshToken: refresh_token,
      });
      if (res.status && res.data.access_token) {
        if (config.environment === 'v2Development') {
          console.log('V20231103: Token successfully refreshed.');
        }
        const { access_token, expiry_date } = res.data;
        apiHandler.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        setEncryptedLocalData(
          access_token,
          localKeys.GOOGLE_OAUTH,
          secretKeys.GOOGLE_OAUTH
        );
        setEncryptedLocalData(
          { ...res.data },
          localKeys.GOOGLE_OAUTH_DATA,
          secretKeys.GOOGLE_OAUTH_DATA
        );
        await editProfile({
          id: profile?.id || userDocId,
          rt: refresh_token,
          t_expire: expiry_date,
        });
        tokenExpireHandler(expiry_date, refresh_token);
      }
    } catch (error) {
      if (config.environment === 'v2Development') {
        console.log(
          'V20231103: Failed to refresh token: please contact team@universily.com',
          error
        );
      }
    }
  };

  const getUser = () => {
    return user;
  };

  return {
    user: profile || user,
    getUser,
    tokenExpireHandler,
    fetchData,
    isLoading,
  };
};

export default useAuth;
