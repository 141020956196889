import { useRef } from 'react';
import { SnackbarProvider } from 'notistack';

import { SnackbarStyles } from '../../styles/snackbarStyles';

const NotiStackProvider = ({ children }) => {
  const notistackRef = useRef(null);

  //   const onClose = (key) => () => {
  //     notistackRef.current.closeSnackbar(key);
  //   };
  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};

export default NotiStackProvider;
