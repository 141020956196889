// UNIVERSILY ROUTES //

// AUTH ROUTES
export const SIGN_UP = '/signUp';
export const REVIEWER_SIGN_UP = '/reviewerSignUp';
export const SIGN_IN = '/signIn';
export const FORGOT_PASSWORD = '/resetPassword';
export const ACCEPT_TEAM_INVITE = '/acceptInvitation';
export const ONBOARDING = '/onboarding';
export const ADMIN_LOGIN = '/adminLogin';

// DASHBOARD ROUTES
export const ROOT = '/';
export const ADMIN_ROOT = '/admin';
export const MY_STORY = '/myStory';
export const COURSES = '/myStory/courses';
export const ACTIVITIES = '/myStory/activities';
export const HONORS = '/myStory/honors';
export const TESTS = '/myStory/tests';
export const NICHE = '/myStory/niche';
export const MY_ESSAY = '/myEssay';
export const MY_COLLEGES = '/myColleges';
export const MY_TEAM = '/myTeam';
export const MY_HUB = '/myHub';
export const COLLEGE_DETAIL = '/collegeDetail';
export const MY_ACCOUNT = '/myAccount/account';
export const MY_ACCOUNT_SUBSCRIPTIONS = '/myAccount/subscriptions';
export const USER_LOGIN_DETAILS = ADMIN_ROOT + '/userLoginDetail';
export const COLLEGES = ADMIN_ROOT + '/colleges';
export const UNIVERSILY_TEMPLATES = ADMIN_ROOT + '/universilyTemplates';
export const COMMON_ESSAYS = ADMIN_ROOT + '/commonEssays';
export const INVITE_FRIEND = '/referAFriend';
export const MY_HOME = '/myHome';
export const MY_STUDENTS = '/myStudents';
export const ACCEPT_REQUEST = '/acceptRequest';
