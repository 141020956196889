import React, { useContext, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../../assets/svgs/Logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import {
  sideBarBottomNavItems,
  sideBarItems,
  adminDashboard,
  adminSideBarBottomNavItems,
} from '../../../constants/staticData.js';

import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { signOut } from '../../../firebase/services/auth.js';
import { UserProfileContext } from '../../../contexts/userContext.js';
import useProfileStore from '../../../stores/ProfileStore';
import { userTypeKeywords } from '../../../constants/keywords';
import { checkForPremiumAccess } from '../../../utils/checkForPremiumAccess';
import { ChevronRight } from '@mui/icons-material';
import Tooltip from '../Tooltip';

const drawerWidth = 240;
const miniDrawerWidth = 80;

const SideBar = ({ isSideBarOpen, setIsSideBarOpen }) => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { setUserProfile } = useContext(UserProfileContext);
  const [sidebarContent, setSidebarContent] = useState([]);
  const [sidebarBottomContent, setSidebarBottomContent] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const { profile, setProfile } = useProfileStore();

  const isLicenseActive =
    profile?.email === 'team@universily.com' ||
    profile?.email === 'admin@universily.com' ||
    profile?.email === 'askuni@universily.com'
      ? true
      : checkForPremiumAccess(profile?.licenseData?.subscriptionStatus);

  useEffect(() => {
    if (profile?.profileType === userTypeKeywords.ADMINISTRATOR) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [profile]);

  // Handlers Methods
  const handleNavigation = (path) => {
    if (path) {
      navigate(path);
      setIsSideBarOpen(false);
    } else {
      setUserProfile(null);
      setProfile(null);
      signOut();
    }
  };

  useEffect(() => {
    if (isAdmin) {
      setSidebarContent(adminDashboard);
      setSidebarBottomContent(adminSideBarBottomNavItems);
    } else {
      setSidebarContent(sideBarItems);
      setSidebarBottomContent(sideBarBottomNavItems);
    }
  }, [isAdmin]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isSideBarOpen ? drawerWidth : miniDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isSideBarOpen ? drawerWidth : miniDrawerWidth,
          transition: 'width 0.3s',
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          zIndex: 79, // Ensure the drawer is below the Essay/honor/Activity modal
        },
      }}
    >
      <div className="flex flex-col justify-between h-full">
        <List>
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: isSideBarOpen ? 'space-between' : 'center',
              padding: '20px',
            }}
          >
            {isSideBarOpen && <LogoIcon />}
            <IconButton onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
              {isSideBarOpen ? <CloseIcon /> : <ChevronRight />}
            </IconButton>
          </ListItem>
          {isLicenseActive &&
            sidebarContent.map(({ id, label, path, icon }) => (
              <Tooltip
                title={!isSideBarOpen ? label : ''}
                key={id}
                placement="right"
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: 'block',
                    textAlign: 'center',
                  }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: isSideBarOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => handleNavigation(path)}
                    selected={location.pathname === path}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isSideBarOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        color: 'inherit', // Ensure icons inherit the text color
                      }}
                    >
                      {icon
                        ? icon(`${
                            location.pathname === path
                              ? 'text-grey-600'
                              : 'text-grey-200'
                          }
  ${window.location.pathname === '/myHub' && 'text-brand-blue'}
  `)
                        : ''}{' '}
                      {/* Ensure SVGs use currentColor */}
                      {/* Ensure SVGs use currentColor */}
                    </ListItemIcon>
                    {isSideBarOpen && <ListItemText primary={label} />}
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            ))}
        </List>
        {/* Second List */}
        <List>
          {sidebarBottomContent.map(({ id, path, label, icon }) => (
            <Tooltip
              title={!isSideBarOpen ? label : ''}
              key={id}
              placement="right"
            >
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: isSideBarOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => handleNavigation(path)}
                  selected={location.pathname === path}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isSideBarOpen ? 3 : 'auto',
                      justifyContent: 'center',
                      // Ensure icons inherit the text color
                    }}
                  >
                    {icon
                      ? icon(`${
                          location.pathname === path
                            ? 'text-grey-600'
                            : 'text-grey-200'
                        }
  ${window.location.pathname === '/myHub' && 'text-brand-blue'}
  `)
                      : ''}
                  </ListItemIcon>
                  {isSideBarOpen && <ListItemText primary={label} />}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default SideBar;
