import { useShallow } from 'zustand/react/shallow';
import { getUserAccess } from '../constants/accessControl';
import useEssayStore from '../stores/EssayStore';
import { UTILITY_TYPES } from './utlityTypes';
import useNicheStore from '../stores/NicheStore';
import useActivityStore from '../stores/ActivityStore';
import useHonorStore from '../stores/HonorStore';
import useCourseStore from '../stores/CourseStore';
import useTestStore from '../stores/TestStore';
import useApplicationStore from '../stores/ApplicationStore';
import useTeamStore from '../stores/TeamStore';

/**
 *
 * @param {Array} items
 */
const useAccessCheck = (items) => {
  const licenseType = useTeamStore(
    useShallow((state) => state.selectedTeam?.licenseData?.licenseType)
  );
  const userAccessDetails = getUserAccess(licenseType);

  const canEdit = (type) => {
    switch (type) {
      case UTILITY_TYPES.Niche:
        return NicheAccessCheck(userAccessDetails);
      case UTILITY_TYPES.Activities:
        return ActivityAccessCheck(userAccessDetails);
      case UTILITY_TYPES.Honors:
        return HonorsAccessCheck(userAccessDetails);
      case UTILITY_TYPES.Courses:
        return CoursesAccessCheck(userAccessDetails);
      case UTILITY_TYPES.Test:
        return TestAccessCheck(userAccessDetails);
      case UTILITY_TYPES.Applications:
        return CollegeAccessCheck(userAccessDetails);
      case UTILITY_TYPES.Essay:
        return EssayAccessCheck(userAccessDetails);
      default:
        return false;
    }
  };

  if (Array.isArray(items)) {
    return items.map((item) => canEdit(item));
  }
};

function NicheAccessCheck(userAccessDetails) {
  const currentNicheCount = useNicheStore(
    useShallow((state) => state.niches?.length)
  );

  return currentNicheCount < userAccessDetails.niches;
}

function ActivityAccessCheck(userAccessDetails) {
  const currentActivityCount = useActivityStore(
    useShallow((state) => state.activities?.length)
  );

  return currentActivityCount < userAccessDetails.activities;
}

function HonorsAccessCheck(userAccessDetails) {
  const currentHonorsCount = useHonorStore(
    useShallow((state) => state.honors?.length)
  );

  return currentHonorsCount < userAccessDetails.honors;
}

function CoursesAccessCheck(userAccessDetails) {
  const currentCoursesCount = useCourseStore(
    useShallow((state) => state.courses?.length)
  );

  return currentCoursesCount < userAccessDetails.courses;
}

function TestAccessCheck(userAccessDetails) {
  const currentTestCount = useTestStore(
    useShallow((state) => state.testTypes?.length)
  );

  return currentTestCount < userAccessDetails.tests;
}

function CollegeAccessCheck(userAccessDetails) {
  const currentCollegeCount = useApplicationStore(
    useShallow((state) => state.applications?.length)
  );

  return currentCollegeCount < userAccessDetails.colleges;
}

function EssayAccessCheck(userAccessDetails) {
  const currentEssayCount = useEssayStore(
    useShallow((state) => state.essays?.length)
  );

  return currentEssayCount < userAccessDetails.essays;
}

export default useAccessCheck;
