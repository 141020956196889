import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
export const HeaderMain = styled('div')(({ theme }) => ({
  //   backgroundColor: theme.palette.primary.main,
  padding: '17px 42px',
  display: 'flex',
  justifyContent: 'space-beetween',
  alignItems: 'center',
  border: '1px solid #EAEAEB',
  [theme.breakpoints.down('md')]: {
    padding: '15px 20px 13px',
  },
}));
export const LeftHeader = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  '.pr-icon': {
    padding: '0px 10px 0px 0px',
    minWidth: 'auto',
  },
  '.logo-header': {
    svg: {
      [theme.breakpoints.down('md')]: {
        width: '110px',
      },
    },
  },
  a: {
    transition: '0.5s',
  },
}));
export const RightHeader = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'flex-end',
  // '.pr-icon': {
  //   paddingRight: '40px',
  //   [theme.breakpoints.down('md')]: {
  //     paddingRight: '25px',
  //   },
  // },
  a: {
    transition: '0.5s',
  },
}));
export const ButtonRightHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
export const CustomIconHeader = styled('div')(({ theme }) => ({
  paddingRight: '40px',
  [theme.breakpoints.down('md')]: {
    paddingRight: '25px',
  },
}));
export const CustomIconButton = styled(IconButton)({
  '&:hover': {
    svg: {
      transition: '0.5s',
      fill: '#1958CD',
      path: {
        fill: '#1958CD',
      },
    },
  },
});
