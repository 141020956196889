import Button from '../shared/Button';
import AddIcon from '@mui/icons-material/Add';
import { GenericTable } from '../shared/Table/Table';
import { useNavigate } from 'react-router-dom';
import useProfileStore from '../../stores/ProfileStore';
import { CustomRow } from '../shared/Table/CustomRow';
import { filterUniAndTeamUniversily } from '../../utils/utlityTypes';
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip from '../shared/Tooltip';
import RemoveStudentDialog from './RemoveStudentDialog';
import useTeamStore from '../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import {
  leaveTeam,
  updateDocument,
} from '../../firebase/services/updateServices';

import EditIcon from '@mui/icons-material/Edit';
import { openSnackbar } from '../shared/GlobalSnackbar/GlobalSnackbar';

import EditTeamNameDialog from './EditTeamNameDialog';
import { useEffect } from 'react';
const columns = [
  { key: 'teamName', label: 'Team Name' },
  { key: 'members', label: 'Members' },
  { key: 'dateAdded', label: 'Date Added' },
  { key: 'studentEmail', label: 'Student Email' },
];

const MyStudentListPanel = ({
  onAddTeamMember = () => {},
  sendInviteToReviewer = () => {},
}) => {
  const profile = useProfileStore((state) => state.profile);
  const navigate = useNavigate();

  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      setTeams: state.setTeams,
      isLeavingTeam: state.isLeavingTeam,
      setIsLeavingTeam: state.setIsLeavingTeam,
      leaveTeamId: state.leaveTeamId,
      setLeaveTeamId: state.setLeaveTeamId,
      isEditingTeamName: state.isEditingTeamName,
      setIsEditingTeamName: state.setIsEditingTeamName,
      editTeamNameData: state.editTeamNameData,
      setEditTeamNameData: state.setEditTeamNameData,
    }))
  );
  const modifiedTeamMembers = teamStore?.teams?.map((teamMember) => {
    return {
      id: teamMember?.id,
      teamName: teamMember?.teamName,
      members: filterUniAndTeamUniversily(teamMember?.teamMembers)?.length,
      dateAdded: teamMember?.createdAt?.toDate()?.toLocaleDateString(),
      studentEmail: teamMember.studentEmail,
    };
  });

  // Filter teams based on profile teams, this is to fix re-render issue after leaving the team
  useEffect(() => {
    const updatedTeams = teamStore.teams.filter((team) =>
      profile?.teams?.map((team) => team.teamId).includes(team.id)
    );
    teamStore.setTeams(updatedTeams);
    // Dependency array is teams length, so that it will re-render only when teams length changes
  }, [profile?.teams?.length]);

  const actions = [
    {
      id: 'edit',
      label: (row) => {
        return (
          <Tooltip title="Edit Team name">
            <EditIcon />
          </Tooltip>
        );
      },
      handler: (row) => {
        console.log('row', row);
        teamStore.setIsEditingTeamName(true);
        teamStore.setEditTeamNameData({
          id: row?.id,
          teamName: row?.teamName,
        });
      },
    },
    {
      id: 'leave',
      label: (row) => {
        if (profile?.profileType === 'Student') {
          return null;
        }
        return (
          <Tooltip title="Leave Team">
            <DeleteIcon />
          </Tooltip>
        );
      },
      handler: (row) => {
        console.log('row', row);
        teamStore.setIsLeavingTeam(true);
        teamStore.setLeaveTeamId(row.id);
      },
    },
  ];

  const handleNavigateToTeamDetails = (teamId) => {
    navigate(teamId);
  };

  const onLeave = async (row) => {
    const leaveTeamRes = await leaveTeam(teamStore.leaveTeamId, profile.uid);
    return leaveTeamRes;
  };

  const onEditTeamName = async (row) => {
    await updateDocument(
      'teams',
      'id',
      teamStore.editTeamNameData.id,
      'teamName',
      teamStore.editTeamNameData.teamName
    );
    teamStore.setIsEditingTeamName(false);
    openSnackbar('Team name updated successfully', 'success');
  };
  return (
    <div className="flex flex-col gap-8 bg-cream-50 sm:p-4 overflow-y-hidden rounded-xl border h-full">
      <div className="flex items-center justify-between py-2 px-4">
        <div className="text-xl sm:text-2xl text-primary-black font-bold">
          My Teams
        </div>
        {/* Only show button to parents */}
        {profile?.profileType !== 'Student' ? (
          <Button startIcon={<AddIcon />} onClick={onAddTeamMember}>
            Add New Team
          </Button>
        ) : null}
      </div>
      <div className="flex overflow-y-auto">
        <GenericTable
          actions={actions}
          columns={columns}
          data={modifiedTeamMembers}
          renderRow={(row, rowIndex, columns, actions) => (
            <CustomRow
              actions={actions}
              row={row}
              rowIndex={rowIndex}
              profile={profile}
              columns={columns}
              handleNavigateToTeamDetails={handleNavigateToTeamDetails}
            />
          )}
        />
      </div>
      <RemoveStudentDialog
        buttonText="Leave"
        titleText="Leave Team"
        bodyText="Are you sure you want to leave this team?"
        open={teamStore.isLeavingTeam}
        onCancel={() => {
          teamStore.setIsLeavingTeam(false);
          teamStore.setLeaveTeamId(null);
        }}
        onContinue={async () => {
          const res = await onLeave();
          if (res) {
            teamStore.setIsLeavingTeam(false);
            openSnackbar('Left team successfully', 'success');
          }
        }}
      />
      {/* To edit team Name */}
      <EditTeamNameDialog
        onEditTeamName={onEditTeamName}
        teamStore={teamStore}
      />
    </div>
  );
};

export default MyStudentListPanel;
