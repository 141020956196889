export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  TRIAL_ACTIVE: 'TRIAL_ACTIVE',
  TRIAL_CANCELLED: 'TRIAL_CANCELLED',
  SUBSCRIPTION_CANCELLED: 'CANCELLED',
};
export const checkForPremiumAccess = (subscriptionStatus) => {
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
    subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_ACTIVE ||
    subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_CANCELLED ||
    subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIPTION_CANCELLED
  ) {
    return true;
  }
  return false;
};
