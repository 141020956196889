import { Close, Search } from '@mui/icons-material';
import { Divider, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useChatStore from '../../stores/ChatStore';
import Tooltip from '../shared/Tooltip';
import ChatListGroup from './ChatListGroup';
import useThemeStore from '../../stores/ThemeStore';

const ChatList = ({
  teamMembers = [],
  userId = '',
  onAddTopic = () => {},
  onAddTeamMember = () => {},
  chatTopics = [],
  chatDMs = [],
  onChatClick = () => {},
  createDMChannel = () => {},
  onArchiveTopicMessage = () => {},
  archivedChatTopics = [],
  archivedChatDMs = [],
  markMessagesAsRead = () => {},
  markMessagesAsUnread = () => {},
  onInviteAStudent = () => {},
  isParent = false,
}) => {
  const chatStore = useChatStore();
  const { isMobile } = useThemeStore();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [groupChatRooms, setGroupChatRooms] = useState(chatTopics);
  const [directMessages, setDirectMessages] = useState(chatDMs);
  const [archivedTopics, setArchivedTopics] = useState(archivedChatTopics);
  const [archivedDM, setArchivedDM] = useState(archivedChatDMs);
  const [showSearchInput, setShowSearchInput] = useState(false);

  useEffect(() => {
    setGroupChatRooms(
      chatTopics?.filter((chat) =>
        chat.topicName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
      )
    );
    setDirectMessages(
      chatDMs.filter((chat) => {
        const label = `${chat?.firstName} ${chat?.lastName}`;
        if (label?.toLowerCase()?.includes(searchKeyword?.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
    setArchivedTopics(
      archivedChatTopics.filter((chat) =>
        chat.topicName?.toLowerCase().includes(searchKeyword?.toLowerCase())
      )
    );
    setArchivedDM(
      archivedChatDMs.filter((chat) => {
        const label = `${chat.firstName} ${chat.lastName}`;
        if (label?.toLowerCase().includes(searchKeyword?.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
    //eslint-disable-next-line
  }, [searchKeyword]);

  useEffect(() => {
    setGroupChatRooms(chatTopics);
  }, [chatTopics]);

  useEffect(() => {
    setDirectMessages(chatDMs);
  }, [chatDMs]);

  useEffect(() => {
    setArchivedTopics(archivedChatTopics);
  }, [archivedChatTopics]);

  useEffect(() => {
    setArchivedDM(archivedChatDMs);
  }, [archivedChatDMs]);

  useEffect(() => {
    if (!showSearchInput) {
      setGroupChatRooms(chatTopics);
      setDirectMessages(chatDMs);
      setArchivedTopics(archivedChatTopics);
      setArchivedDM(archivedChatDMs);
      setSearchKeyword('');
    }
    //eslint-disable-next-line
  }, [showSearchInput]);

  return (
    <div className="px-6 overflow-y-scroll">
      <div className="w-full relative flex gap-3 sm:gap-0 items-center py-6">
        {isMobile && (
          <Tooltip title="Close">
            <IconButton>
              <ArrowBackIcon
                onClick={() => chatStore.setIsChatWindowOpen(false)}
              />
            </IconButton>
          </Tooltip>
        )}
        {showSearchInput && (
          <TextField
            id="outlined-basic"
            className="w-full absolute"
            variant="outlined"
            placeholder="Search chats"
            value={searchKeyword}
            onChange={(event) => setSearchKeyword(event.target.value)}
            InputProps={{
              endAdornment: (
                <Tooltip title="Close search">
                  <div>
                    <IconButton
                      onClick={() => setShowSearchInput(!showSearchInput)}
                    >
                      <Close />
                    </IconButton>
                  </div>
                </Tooltip>
              ),
            }}
          />
        )}
        {!showSearchInput && (
          <div className="flex w-full items-center">
            <div className="font-bold">Chats</div>
            <Tooltip title="Search">
              <div className="ml-auto">
                <IconButton
                  onClick={() => setShowSearchInput(!showSearchInput)}
                >
                  <Search />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          borderColor: '#BABAAE',
          opacity: '20%',
        }}
      />
      <ChatListGroup
        userId={userId}
        title="Group Chats"
        type="groupChats"
        onAdd={onAddTopic}
        itemList={groupChatRooms}
        onChatClick={onChatClick}
        onArchiveTopicMessage={onArchiveTopicMessage}
        markMessagesAsRead={markMessagesAsRead}
        markMessagesAsUnread={markMessagesAsUnread}
        isSearching={searchKeyword !== ''}
        alreadyExpanded={true}
        showViewAll={chatStore.isFilterTopic}
      />
      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          borderColor: '#BABAAE',
          opacity: '20%',
        }}
      />
      <ChatListGroup
        markMessagesAsRead={markMessagesAsRead}
        createDMChannel={createDMChannel}
        userId={userId}
        title="Direct Messages"
        type="directMessage"
        onAdd={() => {
          if (!isParent) {
            onAddTeamMember();
          } else {
            onInviteAStudent();
          }
        }}
        itemList={directMessages}
        teamMembers={teamMembers}
        onChatClick={onChatClick}
        isParent={isParent}
        onArchiveTopicMessage={onArchiveTopicMessage}
        markMessagesAsUnread={markMessagesAsUnread}
        isSearching={searchKeyword !== ''}
        alreadyExpanded={true}
      />
      {archivedChatTopics?.length > 0 && (
        <>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              borderColor: '#BABAAE',
              opacity: '20%',
            }}
          />
          <ChatListGroup
            createDMChannel={createDMChannel}
            userId={userId}
            title="Archived Topics"
            type="archivedTopic"
            onAdd={onAddTeamMember}
            itemList={archivedTopics}
            teamMembers={teamMembers}
            onChatClick={onChatClick}
            onArchiveTopicMessage={onArchiveTopicMessage}
            markMessagesAsRead={markMessagesAsRead}
            markMessagesAsUnread={markMessagesAsUnread}
            alreadyExpanded={false}
          />
        </>
      )}
      {archivedChatDMs?.length > 0 && (
        <>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              borderColor: '#BABAAE',
              opacity: '20%',
            }}
          />
          <ChatListGroup
            createDMChannel={createDMChannel}
            markMessagesAsRead={markMessagesAsRead}
            userId={userId}
            title="Archived Messages"
            type="archivedMessage"
            onAdd={onAddTeamMember}
            itemList={archivedDM}
            teamMembers={teamMembers}
            onChatClick={onChatClick}
            onArchiveTopicMessage={onArchiveTopicMessage}
            markMessagesAsUnread={markMessagesAsUnread}
            alreadyExpanded={false}
          />
        </>
      )}
    </div>
  );
};

export default ChatList;
