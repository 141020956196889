import { statusIndicatorLabels } from '../../constants/other';
import Tooltip from './Tooltip';

export const StatusIndicator = ({
  label = '',
  tooltipLabel = '',
  status = '',
}) => {
  const getLabel = (label) => {
    const statusLabel = statusIndicatorLabels.find(
      (item) => item?.value === label
    )?.label;
    return statusLabel;
  };

  return (
    <Tooltip title={tooltipLabel}>
      <div className="flex gap-2 items-center w-fit">
        {statusIndicatorLabels.find((item) => item.value === status)?.icon}
        {label && (
          <div className="text-grey-700 text-sm shrink-0">
            {getLabel(label)}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
