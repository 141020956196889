import { db, auth } from '../config';
import {
  collection,
  query,
  where,
  getDocs,
  getCountFromServer,
  getDoc,
  doc,
  updateDoc,
  serverTimestamp,
  addDoc,
} from 'firebase/firestore';
import { getLocalUser } from '../../utils/localStorage';
import { localKeys } from '../../constants/localStorage';
import {
  APPLICATION_TEMPLATES,
  CHAT_MESSAGES,
  CHAT_ROOMS,
  COLLEGES_LIST,
  NICHE,
  PROFILE,
  REFERRAL,
  REVIEWER,
} from '../constants';

const user = getLocalUser(localKeys.AUTH);

// GET NICHE
export const getNiche = (nicheId) =>
  new Promise((resolve) => {
    const getNicheQuery = query(
      collection(db, NICHE),
      where('createdBy', '==', auth?.currentUser?.uid || user?.uid),
      where('id', '==', nicheId),
      where('deleteDocument', '==', false)
    );
    let fetchedNiches = [];
    getDocs(getNicheQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedNiches.push(fetchedNiche);
        });
        resolve(fetchedNiches[0]);
      })
      .catch((error) => resolve(false));
  });

export const getMessagesForChatRoom = async (chatRoomId, userId, readValue) => {
  let chatMessages = [];
  const messageQuery = query(
    collection(db, CHAT_ROOMS, chatRoomId, CHAT_MESSAGES),
    where('isRead', '==', readValue),
    where('senderId', '!=', userId)
  );
  await getDocs(messageQuery).then((querySnapshot) => {
    const newObj = querySnapshot.docs.map((doc) => doc.data());
    chatMessages = newObj;
  });

  return chatMessages;
};

export const getMessagesForChat = async (chatRoomId) => {
  let chatMessages = [];
  const messageQuery = query(
    collection(db, CHAT_ROOMS, chatRoomId, CHAT_MESSAGES)
  );
  getDocs(messageQuery).then((querySnapshot) => {
    const newObj = querySnapshot.docs.map((doc) => doc.data());
    chatMessages = newObj;
  });

  return chatMessages;
};

export const getUnReadMessageCount = async (chatRoomId, userId) => {
  if (chatRoomId && userId) {
    const messageQuery = query(
      collection(db, CHAT_ROOMS, chatRoomId, CHAT_MESSAGES),
      where('isRead', '==', false),
      where('senderId', '!=', userId)
    );
    return await getCountFromServer(messageQuery)
      .then((result) => result.data().count)
      .then((result) => {
        return result;
      });
  } else return 0;
};

export const getReferralData = (emailId) => {
  return new Promise((resolve) => {
    const getReferralQuery = query(
      collection(db, REFERRAL),
      where('email', '==', emailId),
      where('deleteDocument', '==', false)
    );
    let fetchedNiches = [];
    getDocs(getReferralQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedNiches.push(fetchedNiche);
        });
        resolve(fetchedNiches[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const getReveiewerDetails = (emailId, createdBy) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('email', '==', emailId),
      where('createdBy', '==', createdBy),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const getReveiewerTeamDetails = (emailId, createdBy) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('studentEmail', '==', emailId),
      where('reviewerId', '==', createdBy),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};
export const getProfileDetails = (emailId) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, PROFILE),
      where('email', '==', emailId),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};
export const getActiveStudentReviewers = (activeStudentUID) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('revieweeId', '==', activeStudentUID),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then(async (response) => {
        for (let index = 0; index < response.docs.length; index++) {
          const document = response.docs[index];
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          // Wait for each getProfileDetails call to finish before proceeding
          const result = await getProfileDetails(fetchedNiche.email);
          fetchedReviewer.push({ reviewerDocId: document.id, ...result });
        }
        resolve(fetchedReviewer);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const getLoggedInReviewerId = (loggedInParentEmail, activeStudentId) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('email', '==', loggedInParentEmail),
      where('revieweeId', '==', activeStudentId),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

// Get Colleges List
export const getCollegesList = (isAll = false) =>
  new Promise((resolve) => {
    const getCollegesQuery = isAll
      ? query(collection(db, COLLEGES_LIST))
      : query(
          collection(db, COLLEGES_LIST),
          where('deleteDocument', '==', false)
        );
    let fetchedColleges = [];
    getDocs(getCollegesQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedCollege = {
            id: document.id,
            ...document.data(),
          };
          fetchedColleges.push(fetchedCollege);
        });
        resolve(
          fetchedColleges?.sort((a, b) =>
            a?.collegeName?.localeCompare(b?.collegeName)
          )
        );
      })
      .catch(() => resolve(fetchedColleges));
  });

export const fetchApplicationRequirements = (userId, isAll = false) =>
  new Promise((resolve) => {
    const getQuery = isAll
      ? query(collection(db, APPLICATION_TEMPLATES))
      : query(
          collection(db, APPLICATION_TEMPLATES),
          where('deleteDocument', '==', false)
        );
    let fetchedRequirements = [];
    getDocs(getQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedRequirement = {
            id: document.id,
            ...document.data(),
          };
          fetchedRequirements.push(fetchedRequirement);
        });
        resolve(fetchedRequirements);
      })
      .catch((error) => {
        resolve(fetchedRequirements);
      });
  });

export const getStoryItem = (collectionName, queryList = null) =>
  new Promise((resolve) => {
    const dbQuery = queryList
      ? query(
          collection(db, collectionName),
          ...queryList.map(({ property, operator, value }) =>
            where(property, operator, value)
          )
        )
      : query(collection(db, collectionName));
    let fetchedData = [];
    getDocs(dbQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedDoc = {
            id: document.id,
            ...document.data(),
          };
          fetchedData.push(fetchedDoc);
        });
        resolve(fetchedData);
      })
      .catch(() => {
        resolve('');
      });
  });

export const getDocumentByUtilityId = (collectionName, utilityId) => {
  return new Promise((resolve, reject) => {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where('utilityId', '==', utilityId));

    getDocs(q)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const documents = [];
          querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
          });
          resolve(documents);
        } else {
          reject(new Error('No document found with the given utilityId'));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// fetchDocuments: A utility function to fetch documents from a collection based on a list of values
// Helper function to chunk an array into smaller arrays of a specified size
function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

export async function fetchDocuments(
  collectionName,
  values,
  key = null,
  field = null
) {
  try {
    // Determine the type of array elements
    const isArrayOfObjects =
      typeof values[0] === 'object' && values[0] !== null;

    // If it's an array of objects, key must be provided
    if (isArrayOfObjects && !key) {
      throw new Error('Key must be provided for an array of objects.');
    }

    // Field to be used for querying
    const queryField = field || (isArrayOfObjects ? key : '__name__');

    // Chunk the values into smaller arrays of maximum size 10
    const chunkedValues = chunkArray(
      isArrayOfObjects ? values.map((obj) => obj[key]) : values,
      10
    );

    // Array to hold the fetched documents
    const documents = [];

    // Fetch documents for each chunk
    for (const chunk of chunkedValues) {
      const q = query(
        collection(db, collectionName),
        where(queryField, 'in', chunk)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
    }

    return documents;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    throw error;
  }
}

/* 
BE CAREFUL: 
Utility function to update chat rooms with the latest chat participants 
This function is used to update the chatParticipants property in the chatRooms collection for existing data
*/
export const updateChatRooms = async () => {
  try {
    // Step 1: Fetch all documents from the 'honor' collection
    const docsSnapshot = await getDocs(collection(db, 'collectionName'));

    docsSnapshot.forEach(async (document) => {
      const data = document.data();

      // Step 2: Iterate over the reviewers key to get reviewerId from the 'reviewer' collection
      if (!data.reviewers) return;

      const reviewerPromises = data.reviewers.map(async (reviewerKey) => {
        const reviewerDoc = await getDoc(doc(db, 'reviewer', reviewerKey));
        return reviewerDoc.exists() ? reviewerDoc.data().reviewerId : null;
      });

      // Resolve all reviewer promises
      const reviewerIds = (await Promise.all(reviewerPromises)).filter(
        (id) => id !== null
      );

      // Step 3: Search for the documentId in the 'chatRooms' collection and update the chatParticipants property
      const chatRoomQuery = query(
        collection(db, 'chatRooms'),
        where('utilityId', '==', data.id)
      );
      const filteredReviewers = reviewerIds.filter((id) => id !== undefined);
      console.log('processingDocumentId', { id: data.id, filteredReviewers });
      const chatRoomSnapshot = await getDocs(chatRoomQuery);

      if (chatRoomSnapshot.empty) {
        // Chat room does not exist, create a new one
        await addDoc(collection(db, 'chatRooms'), {
          chatParticipants: [...filteredReviewers, data.createdBy],
          createdAt: serverTimestamp(),
          createdBy: data.createdBy, // Adjust as needed
          deleteDocument: false, // Assuming a boolean flag
          isTopic: true,
          messageUpdatedAt: serverTimestamp(),
          updateCount: 0,
          updatedAt: serverTimestamp(),
          updatedBy: data.createdBy, // Adjust as needed
          users: [data.createdBy], // Assuming all participants are users,
          utilityId: data.id,
        });
      } else {
        // Chat room exists, update the chatParticipants property
        chatRoomSnapshot.forEach(async (chatRoomDoc) => {
          await updateDoc(chatRoomDoc.ref, {
            chatParticipants: filteredReviewers.includes(data.createdBy)
              ? filteredReviewers
              : [...filteredReviewers, data.createdBy],
            updatedAt: serverTimestamp(),
            updatedBy: data.createdBy, // Adjust as needed
          });
        });
      }
    });
  } catch (error) {
    console.error('Error updating chat rooms: ', error);
  }
};
