import { useEffect, useRef, useState } from 'react';

import { Outlet } from 'react-router-dom';
import useMyTeamStore from '../../stores/MyTeamStore';
import useProfileStore from '../../stores/ProfileStore';
import useChatStore from '../../stores/ChatStore';
import useThemeStore from '../../stores/ThemeStore';
import { userTypeKeywords } from '../../constants/keywords';
import { ASK_UNI_MAIL } from '../../constants/staticData';
import { addStoryItemTypes } from '../../constants/other';
import { Footer, Header, PageViewTracker } from '../../components';
import ChatWindow from '../../components/chat/ChatWindow';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { addStoryItem } from '../../firebase/services/addServices';
import Sidebar from '../../components/shared/sideBar/index';

const drawerWidth = 240;
const miniDrawerWidth = 80;

const AdminLayout = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const containerRef = useRef();
  const chatStore = useChatStore();
  const profileStore = useProfileStore();
  const myTeamStore = useMyTeamStore();
  const isParent =
    profileStore?.profile?.profileType === userTypeKeywords.FAMILY ||
    profileStore?.profile?.profileType === userTypeKeywords.PRIVATE_COUNSELOR ||
    profileStore?.profile?.profileType === userTypeKeywords.SCHOOL_COUNSELOR ||
    profileStore?.profile?.profileType === userTypeKeywords.TEACHER ||
    profileStore?.profile?.profileType === userTypeKeywords.UNIVERSILY;
  const { isMobile } = useThemeStore();

  useEffect(() => {
    if (chatStore.isChatWindowOpen) {
      setHeight(
        isMobile
          ? containerRef.current.clientHeight
          : containerRef.current.clientHeight - 64
      );
      setWidth(isMobile ? containerRef?.current?.clientWidth : 400);
    } else {
      setHeight(0);
      setWidth(0);
    }
  }, [chatStore.isChatWindowOpen, isMobile]);

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  const findCount = (arr) => {
    let count = 0;
    arr.forEach((chat) => {
      count += chat.unreadMessages;
    });
    return count;
  };

  useEffect(() => {
    // const topicsUnread = findCount(chatStore.chatRoomsTopics);
    const dmUnread = findCount(chatStore.chatRoomsDM);
    setTotalUnreadCount(dmUnread);
  }, [chatStore.chatRoomsDM]);

  const handleAskUniChatClick = async () => {
    if (chatStore.isGenAIChatOpen) {
      chatStore.setIsChatOpen(false);
      chatStore.setOpenChatId(null);
      chatStore.setOpenChatType(null);
      chatStore.setUtilityId(null);
      chatStore.setIsGenAIChatOpen(false);
      chatStore.setIsChatWindowOpen(false);
      chatStore.setIsFilterTopic(false);
    } else {
      const chatTopic = chatStore.chatRoomsDM.find(
        (chatDM) => chatDM.email === ASK_UNI_MAIL
      );
      if (chatTopic?.chatParticipants) {
        chatStore.setOpenChatId(chatTopic.id);
        chatStore.setOpenChatType('directMessage');
        chatStore.setIsChatWindowOpen(true);
      } else {
        const teamUniversily = myTeamStore.myTeam.find(
          (teamMember) => teamMember.email === ASK_UNI_MAIL
        );
        if (teamUniversily) {
          const reviewer = await getProfileDataByEmail(ASK_UNI_MAIL);
          const response = await addStoryItem(
            {
              isTopic: false,
              archivedBy: [],
              chatParticipants: [reviewer?.uid, profileStore.profile?.uid],
            },
            addStoryItemTypes.CHAT_ROOM
          );
          if (response) {
            chatStore.setOpenChatType('directMessage');
            chatStore.setOpenChatId(response);
            chatStore.setIsChatWindowOpen(true);
          }
        }
      }
      chatStore.setIsGenAIChatOpen(true);
      chatStore.setIsChatWindowOpen(true);
    }
  };

  return (
    <div className="h-full flex overflow-hidden flex-col" ref={containerRef}>
      <PageViewTracker />
      {/* Header */}
      <div
        style={{
          marginLeft: isSideBarOpen ? drawerWidth : miniDrawerWidth,
          transition: 'margin-left 0.3s',
        }}
      >
        <Header
          onMenuIconClick={() => setIsSideBarOpen(!isSideBarOpen)}
          isChatWindowOpen={chatStore.isChatWindowOpen}
          onChatIconClick={() => {
            chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
          }}
          notification={totalUnreadCount}
          isMobile={isMobile}
          onGenAIIconClick={handleAskUniChatClick}
          isGenAIChatOpen={chatStore.isGenAIChatOpen}
        />
      </div>
      <ChatWindow
        height={height}
        setOpenChatWindow={(value) => chatStore.setIsChatWindowOpen(value)}
        openChatWindow={chatStore.isChatWindowOpen}
        isParent={isParent}
        width={width}
      />

      {/* SideBar, same for both roles parent and student*/}
      <Sidebar
        setIsSideBarOpen={setIsSideBarOpen}
        isSideBarOpen={isSideBarOpen}
      />

      <div
        className="flex-1 flex h-full flex-col !overflow-y-auto"
        style={{
          marginLeft: isSideBarOpen ? drawerWidth : miniDrawerWidth,
          transition: 'margin-left 0.3s',
        }}
      >
        {/* Dynamic Component */}
        <Outlet />
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AdminLayout;
