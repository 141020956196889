import styled from '@emotion/styled';
import { MenuItem as MENUITEM } from '@mui/material';
import { alpha } from '@mui/system';

const MenuItem = styled(MENUITEM)(({ theme }) => ({
  color: '#616675',
  borderRadius: 4,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    fontWeight: 500,
  },
}));

export default MenuItem;
