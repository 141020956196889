import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1958CD',
      contrastText: '#FFFFFF',
    },
    purple: {
      main: '#5542F6',
      contrastText: '#FFFFFF',
    },
    yellow: {
      main: '#FFA043',
      contrastText: '#FFFFFF',
    },
    blue: {
      main: '#00A5FF',
      contrastText: '#FFFFFF',
    },
    green: {
      main: '#20C9AC',
      contrastText: '#FFFFFF',
    },

    complete: {
      main: '#47C257',
      contrastText: '#FFFFFF',
    },
    pending: {
      main: '#616675',
      contrastText: '#FFFFFF',
    },
    red: {
      main: '#FC3400',
      contrastText: '#FFFFFF',
    },
    lightPurple: {
      main: '#9F73E6',
      contrastText: '#FFFFFF',
    },
    darkBlue: {
      main: '#2944C9',
      contrastText: '#FFFFFF',
    },
    darkGrey: {
      main: '#22252D',
      contrastText: '#FFFFFF',
    },
    grey: {
      main: '#838489',
      contrastText: '#FFFFFF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
