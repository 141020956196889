import create from 'zustand';

const useEssayStore = create((set) => ({
  essays: [],
  essaysById: {},
  setEssays: (essays) =>
    set(() => ({
      essays,
      essaysById: essays.reduce(
        (acc, essay) => ({
          ...acc,
          [essay.id]: essay,
        }),
        {}
      ),
    })),
  commonEssays: [],
  setCommonEssays: (commonEssays) => set(() => ({ commonEssays })),
  isAddingEssay: false,
  setIsAddingEssay: (isAddingEssay) => set(() => ({ isAddingEssay })),
  isAddingEssayForApplication: false,
  setIsAddingEssayForApplication: (isAddingEssayForApplication) =>
    set(() => ({ isAddingEssayForApplication })),
  isAddingCoreEssay: false,
  setIsAddingCoreEssay: (isAddingCoreEssay) =>
    set(() => ({ isAddingCoreEssay })),
  isEditingEssay: false,
  setIsShareUtility: (isShareUtility) => set(() => ({ isShareUtility })),
  isShareUtility: false,
  setShareEssayId: (shareEssayId) => set(() => ({ shareEssayId })),
  shareEssayId: null,
  setIsEditingEssay: (isEditingEssay) => set(() => ({ isEditingEssay })),
  editEssayId: null,
  setEditEssayId: (editEssayId) => set(() => ({ editEssayId })),
  editEssayDocsHeader: false,
  setEditEssayDocsHeader: (editEssayDocsHeader) =>
    set(() => ({ editEssayDocsHeader })),
  isFetchingEssays: false,
  setIsFetchingEssays: (isFetchingEssays) => set(() => ({ isFetchingEssays })),
  isCopyingEssay: false,
  setIsCopyingEssay: (isCopyingEssay) => set(() => ({ isCopyingEssay })),
  isCopyingApplicationEssay: false,
  setIsCopyingApplicationEssay: (isCopyingApplicationEssay) =>
    set(() => ({ isCopyingApplicationEssay })),
  copyEssayId: null,
  setCopyEssayId: (copyEssayId) => set(() => ({ copyEssayId })),
  isDeletingEssay: false,
  setIsDeletingEssay: (isDeletingEssay) => set(() => ({ isDeletingEssay })),
  deleteEssayId: null,
  setDeleteEssayId: (deleteEssayId) => set(() => ({ deleteEssayId })),
  addOrEditEssayDocs: false,
  setAddOrEditEssayDocs: (addOrEditEssayDocs) =>
    set(() => ({ addOrEditEssayDocs })),
  currentSelectedEssay: null,
  currentSelectedEssaySelectedMembers: [],
  addOrEditEssayDocsId: null,
  setAddOrEditEssayDocsId: (addOrEditEssayDocsId) =>
    set((state) => ({ addOrEditEssayDocsId })),
  setCurrentSelectedEssay: (id) =>
    set((state) => ({
      currentSelectedEssay: state.essaysById[id],
    })),
  editCurrentSelectedEssay: (currentSelectedEssay) =>
    set(() => ({ currentSelectedEssay })),
  setCurrentSelectedEssaySelectedMembers: (
    currentSelectedEssaySelectedMembers
  ) => set(() => ({ currentSelectedEssaySelectedMembers })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  isEssayReferenceEssayPreview: false,
  setIsEssayReferenceEssayPreview: (isEssayReferenceEssayPreview) =>
    set(() => ({ isEssayReferenceEssayPreview })),
  essayReferencePreviewId: null,
  setEssayReferenceEssayPreview: (essayReferencePreviewId) =>
    set(() => ({ essayReferencePreviewId })),
  isActionSnackbar: false,
  setIsActionSnackbar: (isActionSnackbar) => set(() => ({ isActionSnackbar })),
  isAddingCollegeApplicationEssay: false,
  setIsAddingCollegeApplicationEssay: (isAddingCollegeApplicationEssay) =>
    set(() => ({ isAddingCollegeApplicationEssay })),
  isAddCollege: false,
  setIsAddCollege: (isAddCollege) => set(() => ({ isAddCollege })),
  collegeIdToCreateNewEssay: null,
  setCollegeIdToCreateNewEssay: (collegeIdToCreateNewEssay) =>
    set(() => ({ collegeIdToCreateNewEssay })),
  essayIdToCreateNewEssay: null,
  setEssayIdToCreateNewEssay: (essayIdToCreateNewEssay) =>
    set(() => ({ essayIdToCreateNewEssay })),
}));

export default useEssayStore;
