import { styled } from '@mui/system';
import Button from './Button';
import { Menu } from '@mui/material';

const MENU = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    border: '1px solid #F4F4F4',
    borderRadius: '16px',
    boxShadow: 'none',
    filter:
      'drop-shadow(0px 14px 16px rgba(16, 24, 40, 0.08)) drop-shadow(0px 4px 6px rgba(16, 24, 40, 0.03))',
    '& .MuiMenu-list': {
      padding: '24px',
    },
  },
}));

export const PopupButton = ({
  onClick = () => {},
  buttonText = '',
  children,
  isMobile = false,
  layoutSpread = false,
  ...props
}) => {
  return (
    <div
      className={`flex items-center justify-center w-2/3 sm:w-full ${
        layoutSpread ? 'sm:justify-end' : 'sm:justify-start'
      }`}
    >
      <Button variant="text" onClick={onClick} disabled={isMobile} {...props}>
        {buttonText}
      </Button>
      <MENU {...props}>{children}</MENU>
    </div>
  );
};
