import React, { useEffect, useState } from 'react';
import { alpha } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '../shared/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  select: {
    borderRadius: '8px !important',
    marginTop: 4,
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      gap: 4,
    },
    '& li': {
      fontSize: 14,
      fontWeight: '500',
      '.Mui-focusVisible': {
        backgroundColor: 'red !important',
      },
    },
    '& .Mui-selected': {
      background: 'linear-gradient(0deg, #E9F0FF, #E9F0FF), #FFFFFF',
      color: '#1958CD',
    },

    '& .MuiMenuItem-root:focus-visible': {
      backgroundColor: 'white !important',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#E9F0FF !important',
    },
  },
});

export const ApplicationStatus = ({
  options = [],
  onChange = () => {},
  disabled = false,
  value = '',
  ...props
}) => {
  const [isColor, setIsColor] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (value !== '') {
      const color = options?.find((menu) => menu.id === value)?.color;
      setIsColor(color);
    }
    //eslint-disable-next-line
  }, [value]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <FormControl fullWidth {...props}>
      <Select
        open={isOpen}
        onOpen={handleOpen}
        onClose={() => setIsOpen(false)}
        disabled={disabled}
        value={value}
        onChange={onChange}
        IconComponent={() => (
          <KeyboardArrowDownIcon
            className={!disabled && 'cursor-pointer'}
            onClick={() => {
              if (!disabled) {
                handleOpen();
              }
            }}
          />
        )}
        MenuProps={{
          classes: { paper: classes.select },
        }}
        sx={{
          width: '100% !important',
          border: `1px solid ${isColor}`,
          background: isColor && alpha(isColor, 0.05),
          '& .MuiSelect-select': {
            color: isColor,
            '& .subText': {
              display: 'none',
            },
          },
          '& .MuiSvgIcon-root': {
            fill: isColor,
            position: 'absolute',
            right: '6px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: '0px !important',
          },
          '& .statusLabel': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        }}
      >
        {options?.map((item) => (
          <MenuItem
            key={item?.id}
            value={item?.id}
            sx={{
              color: '#616675',
              fontSize: '14px',
              fontWeight: 500,
              height: '52px',
              ':hover': {
                svg: {
                  fill: 'white !important',
                },
              },
            }}
          >
            <div className="flex flex-col gap-1.5">
              <div className="statusLabel">{item?.label}</div>
              {item?.subText && (
                <div className="text-grey-300 text-xs subText">
                  {item?.subText}
                </div>
              )}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
