import create from 'zustand';
import { AI_TEMPLATES } from '../constants/keywords';

const useChatStore = create((set) => ({
  isChatWindowOpen: false,
  setIsChatWindowOpen: (isChatWindowOpen) => set(() => ({ isChatWindowOpen })),
  isFetchingChat: true,
  setIsFetchingChat: (isFetchingChat) => set(() => ({ isFetchingChat })),
  chatRoomsTopics: [],
  setChatRoomsTopics: (chatRoomsTopics) => set(() => ({ chatRoomsTopics })),
  chatRoomsDM: [],
  setChatRoomsDM: (chatRoomsDM) => set(() => ({ chatRoomsDM })),
  groupChatRooms: [],
  setGroupChatRooms: (groupChatRooms) => set(() => ({ groupChatRooms })),
  selectedGroupChatId: null,
  setSelectedGroupChatId: (selectedGroupChatId) => set(() => ({ selectedGroupChatId })),
  isAddingTopic: false,
  setIsAddingTopic: (isAddingTopic) => set(() => ({ isAddingTopic })),
  isEditingTopic: false,
  setIsEditingTopic: (isEditingTopic) => set(() => ({ isEditingTopic })),
  editTopicId: null,
  setEditTopicId: (editTopicId) => set(() => ({ editTopicId })),
  isDeletingTopic: false,
  setIsDeletingTopic: (isDeletingTopic) => set(() => ({ isDeletingTopic })),
  deleteTopicId: null,
  setDeleteTopicId: (deleteTopicId) => set(() => ({ deleteTopicId })),
  isChatOpen: false,
  setIsChatOpen: (isChatOpen) => set(() => ({ isChatOpen })),
  isGenAIChatOpen: false,
  setIsGenAIChatOpen: (isGenAIChatOpen) => set(() => ({ isGenAIChatOpen })),
  openChatId: null,
  setOpenChatId: (openChatId) => set(() => ({ openChatId })),
  openChatType: null,
  setOpenChatType: (openChatType) => set(() => ({ openChatType })),
  utilityId: null,
  setUtilityId: (utilityId) => set(() => ({ utilityId })),
  isFilterTopic: false,
  setIsFilterTopic: (isFilterTopic) => set(() => ({ isFilterTopic })),
  applicationPageUtil: null,
  setApplicationPageUtil: (applicationPageUtil) =>
    set(() => ({ applicationPageUtil })),
  isAiTyping: false,
  setIsAiTyping: (isAiTyping) => set(() => ({ isAiTyping })),
  isPromptOpen: true,
  setIsPromptOpen: (isPromptOpen) => set(() => ({ isPromptOpen })),
  promptType: AI_TEMPLATES.INTRO_PROMPT,
  setPromptType: (promptType) => set(() => ({ promptType })),
  promptStoryType: 'general',
  setPromptStoryType: (promptStoryType) => set(() => ({ promptStoryType })),
}));

export default useChatStore;
