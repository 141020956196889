import { styled } from '@mui/system';
import {
  Modal,
  Box,
  IconButton,
  TextField,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@mui/material';

export const ModalCustomBlock = styled(Modal)({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(100, 109, 135, 0.5)',
  },
});
export const BoxCustomModal = styled(Box)({
  border: 'none !important',
  background: 'none !important',
  boxShadow: 'none !important',
  width: '580px !important',
  padding: '0px !important',
});

export const BoxCustomModalInner = styled(Box)({
  background: '#FCFCFD',
  boxShadow: '0px 64px 64px -48px rgba(31, 47, 70, 0.12)',
  borderRadius: '16px',
});
export const CloseModalIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-20px',
  right: '-18px',
}));
export const IconButtonClose = styled(IconButton)({
  backgroundColor: '#FFFFFF !important',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: '50px',
  width: '40px',
  height: '40px',
});
export const BlockModalAdd = styled('div')(({ theme }) => ({}));
export const BlockModalAddTitle = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #EAEAEB',
  padding: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  h2: {
    fontSize: '24px',
    color: '#2C303C',
    fontWeight: '500',
    fontFamily: "'Roboto', sans-serif",
  },
}));

export const BLockModalDetails = styled('div')(({ theme }) => ({
  padding: '24px',
}));
export const BLockModalImg = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  h3: {
    fontSize: '20px',
    fontWeight: '400',
    paddingLeft: '24px',
    color: '#2C303C',
  },
}));
export const BLockModalImgInner = styled('div')(({ theme }) => ({
  width: '54px',
  height: '54px',
  backgroundColor: '#DEEEF4',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.yellow-bg-icon': {
    backgroundColor: '#EFE2CB',
  },
  '&.gray-bg-black': {
    backgroundColor: '#E3E3E3',
  },
}));
export const IconButtonAdd = styled(IconButton)({
  borderRadius: '8px',
  marginBottom: '8px',
  width: '100%',
  display: 'flex',
  padding: '8px 20px 8px 8px',
  justifyContent: 'space-between',
});

export const TextFiledCustom = styled(TextField)({
  borderRadius: '0px',
  display: 'block',
  fontFamily: "'Roboto', sans-serif",

  '.MuiInputBase-root': {
    width: '100%',
    background: 'transparent',
    border: '1px solid #BABBBD',
    borderRadius: '8px',
    color: '#838489',

    input: {
      padding: '18px 24px',
      color: '#838489',
      fontWeight: '400',
    },
    '&:before': {
      content: 'none',
    },
    '&:after': {
      content: 'none',
    },
  },
});

export const BLockModalDetailsBlock = styled('div')(({ theme }) => ({
  marginBottom: '15px',
  label: {
    letterSpacing: '0.002em',
    fontSize: '16px',
    fontWeight: '400',
    marginBottom: '12px',
    color: '#7D7D7D',
    display: 'block',
  },
}));

export const RadioGroupCustom = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  '.MuiTypography-root': {
    fontSize: '16px',
  },
  '.MuiFormControlLabel-root:first-of-type': {
    borderRadius: '8px 0px 0px 8px',
  },
  '.MuiFormControlLabel-root:last-of-type(3)': {
    borderRadius: '0px 8px 8px 0px',
  },
  '.MuiFormControlLabel-root': {
    width: 'auto',
    padding: '0px 15px',
  },
});
export const FormControlLabelCustom = styled(FormControlLabel)({
  margin: '0px !important',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '0px',
  width: '108px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '&.nth-child(1)': {
    borderRadius: '8px',
  },
  '.MuiSvgIcon-root': {
    display: 'none',
  },
  '.MuiTypography-root': {
    position: 'relative',
    zIndex: '9',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.MuiButtonBase-root': {
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    left: '0px',
  },
  '.Mui-checked': {
    backgroundColor: '#F1F1F2',
    '+ .MuiTypography-root': {
      color: '#22252D',
    },
  },
});
export const CustomButton = styled(Button)({
  padding: '12px 14px',
  borderRadius: '8px',
  marginTop: '8px',
  '&:hover': {
    background: 'rgba(25, 118, 210, 0.04)',
  },
  p: {
    fontSize: '14px',
    paddingLeft: '10px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
    fontWeight: '500',
  },
  '&.btn-text-cancel': {
    marginTop: '0px',
    fontWeight: '500',
    padding: '8px 14px',
    p: {
      paddingLeft: '0px',
    },
  },
});

export const TopBlockContentDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '3px',
  border: '1px solid #BABBBD',
  borderRadius: '24px',
  margin: '10px 0px 25px',
  p: {
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: '400',
    paddingLeft: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.16px',
  },
}));

export const DeatailIconButton = styled(IconButton)({
  display: 'flex',
  flexDirection: 'row',
  background: 'none !important',
  borderRadius: '0px',
  p: {
    fontWeight: '400',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    marginRight: '15px',
  },
});

export const BlockFooterButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));
export const CustomFillButton = styled(Button)({
  minWidth: 'initial',
  padding: '5px 15px',
  minHeight: 'auto',
  lineHeight: 'normal',
  height: '42px',
  borderRadius: '8px',
  marginLeft: '10px',
  boxShadow: 'none',
  p: {
    textTransform: 'capitalize',
    fontWeight: '600',
  },
});
