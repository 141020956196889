import { query, collection, where } from 'firebase/firestore';
import { firebaseDataType } from '../constants/other';
import { db } from './config';

// Firebase Operators
export const firebaseQueryOperators = {
  LESS_THAN: '<',
  LESS_THAN_EQUAL_TO: '<=',
  EQUAL_TO: '==',
  GREATER_THAN: '>',
  GREATER_THAN_EQUAL_TO: '>=',
  NOT_EQUAL_TO: '!=',
  ARRAY_CONTAINS: 'array-contains',
  ARRAY_CONTAINS_ANY: 'array-contains-any',
  IN: 'in',
  NOT_IN: 'not-in',
};

export const firebaseQueryBuilder = (dataType, collectionName, userId) => {
  switch (dataType) {
    case firebaseDataType.METADATA:
      return query(collection(db, collectionName));
    case firebaseDataType.STUDENT_DATA:
      return query(
        collection(db, collectionName),
        where('createdBy', '==', userId),
        where('deleteDocument', '==', false)
      );
    default:
      return;
  }
};
