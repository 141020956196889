import * as m from 'moment';

export const nicheSampleData = {
  name: 'SAMPLE: Marine Conservationist',
};

export const myStorySampleData = {
  Activity: {
    activityName: 'SAMPLE: Marine Science Summer Camp',
    docInformation: [
      {
        charCount: 110,
        content:
          'I spent the summer at camp exploring marine habitats, learning about marine life, and working with scientists.',
        label: '150 Characters',
      },
    ],
    grades: ['9th'],
    hoursPerWeek: 40,
    weeksPerYear: 4,
    activityTypes: [
      {
        id: 'environmental',
        label: 'Environmental',
      },
    ],
  },
  Honors: {
    honorName: 'SAMPLE: National Ocean Science Bowl',
    docInformation: [
      {
        charCount: 50,
        content: '1st place regional and 3rd place national finish.',
        label: '100 Characters',
      },
    ],
    grades: ['11th'],
    honorTypes: [
      {
        id: '4',
        label: 'National',
      },
    ],
  },
  Course: {
    courseName: 'SAMPLE: AP Biology',
    description:
      'My semester project involved finding ways to improve conservation of our local beaches.',
    grades: ['10th'],
  },
  Tests: {
    testType: 'Amu2tvOnHFhYgejoQTbj',
    targets: [
      {
        subjectName: 'Evidence-Based Reading and Writing',
        subjectId: 'ws8sxff0cNfxaWdiFlLE',
        targetValue: 650,
      },
      {
        subjectName: 'Mathematics',
        subjectId: '0VFAYFoSEmnDu5UmhGJR',
        targetValue: 650,
      },
      {
        subjectName: 'Total',
        subjectId: 'qVM9pTNd28ZPQttz0doQ',
        targetValue: 1300,
      },
    ],
    scores: [
      {
        subjectName: 'Evidence-Based Reading and Writing',
        subjectId: 'ws8sxff0cNfxaWdiFlLE',
        scoreValue: 600,
      },
      {
        subjectName: 'Mathematics',
        subjectId: '0VFAYFoSEmnDu5UmhGJR',
        scoreValue: 620,
      },
      {
        subjectName: 'Total',
        subjectId: 'qVM9pTNd28ZPQttz0doQ',
        scoreValue: 1220,
      },
    ],
    testDate: m('2024-06-01').toDate(),
  },
  GPA: {
    GPAData: {
      unweighted: '3.6',
      unweightedOutOf: '4.0',
      weighted: null,
      weightedOutOf: null,
    },
    RankData: {
      unweighted: null,
      unweightedOutOf: null,
      weighted: null,
      weightedOutOf: null,
    },
  },
};

export const essaysSampleData = {
  type: 'coreEssay',
  essayCategory: 'personalEssay',
  essayName: 'SAMPLE: Core Personal Essay',
  docsInformation: {
    charCount: 1038,
    content:
      'Personal (650 words) Opening sentence. This single sentence may be the most important in the entire essay. You want something that hooks the reader in and makes them want to read more. Paragraph one Following the opening sentence, you want to provide an introduction and greater explanation of your essay’s theme, as well as a lead into the following sections. Paragraph two through X Tie your overall essay theme and niche to your intellectual and scholarly focus. For example, if your niche is oceanography, use this section to describe research you did while an intern at the local aquarium, or an online course you may have taken in the biodiversity of the coral reefs. College is about academics, so you want to show something here that will wow an admissions officer with what you’ll bring to their next class of freshman. Closing Section Pull it all together by talking about how this experience or event impacted you as a student, and what you may have learned that you’ll carry through to college.',
    wordCount: 164,
  },
};

export const collegesSampleData = {
  name: 'Stanford University',
  collegeId: 'k4CqCUeI1C2hXsAjeDVT',
  round: 'l6xwdg3m',
};
