import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { localKeys } from '../../constants/localStorage';
import { getLocalUser } from '../../utils/localStorage';
import { auth, db } from '../config';
import { firebaseQueryBuilder } from '../queryBuilder';

const user = getLocalUser(localKeys.AUTH);
// const studentStore = useStudentStore();
// Master Listener

// Array of Objects : e.g [{property:'deleteDocument',operator : firebaseQueryOperators.EQUAL_TO , false}]
// property : name of field
// operator : firebase query operator (added as firebaseQueryOperators)
// value : value of field

export const listenCollectionData = (
  collectionName,
  callback,
  dataType,
  queryList = null
) => {
  const dataBaseQuery = queryList
    ? queryList.map(({ property, operator, value }) =>
        where(property, operator, value)
      )
    : null;

  const listenCollectionQuery = dataBaseQuery
    ? query(collection(db, collectionName), ...dataBaseQuery)
    : firebaseQueryBuilder(
        dataType,
        collectionName,
        auth?.currentUser?.uid || user?.uid
      );

  return onSnapshot(listenCollectionQuery, (querySnapshot) => {
    const data = [];
    querySnapshot.forEach((doc) => data.push(doc.data()));
    callback(data);
  });
};
