import { InputAdornment, TextField } from '@mui/material';

export const InputField = ({ background = 'white', ...props }) => {
  return (
    <div className="w-full" {...props}>
      <TextField
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        sx={{
          '& .MuiFormLabel-root': {
            background: `${background} !important`,
            paddingRight: '4px !important',
          },
        }}
        style={{
          width: '100%',
        }}
        {...props}
      />
    </div>
  );
};
