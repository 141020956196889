import create from 'zustand';

const useStudentStore = create((set) => ({
  isFetching: false,
  setIsFetching: (isFetching) => set(() => ({ isFetching })),
  student: [],
  setStudent: (student) => set(() => ({ student })),
  selectedStudent: null,
  setSelectedStudent: (selectedStudent) => set(() => ({ selectedStudent })),
  isStudentSelect: false,
  setIsStudentSelect: (isStudentSelect) => set(() => ({ isStudentSelect })),
  studentId: [],
  setStudentId: (studentId) => set(() => ({ studentId })),
  reviewerIdList: [],
  setReviewerIdList: (reviewerIdList) => set(() => ({ reviewerIdList })),
  reviewerForStudent: [],
  setReviewerForStudent: (reviewerForStudent) =>
    set(() => ({ reviewerForStudent })),
  isSideBarOptions: false,
  setIsSideBarOptions: (isSideBarOptions) => set(() => ({ isSideBarOptions })),
  activeStudentReviewers: [],
  setActiveStudentReviewers: (activeStudentReviewers) =>
    set(() => ({ activeStudentReviewers })),
}));

export default useStudentStore;
