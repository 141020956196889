import { auth, db } from '../config';
import {
  doc,
  deleteDoc,
  updateDoc,
  increment,
  serverTimestamp,
  collection,
  getDocs,
} from 'firebase/firestore';
import { COURSE } from '../constants';
import { fireLogEvent } from './analytics';
import {
  ANALYTICS_EVENTS,
  COURSE_EVENTS,
} from '../../constants/firebaseAnalytics';
import { addCollaborator } from './addServices';

// Delete Course
export const deleteCourse = (courseId) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, COURSE, courseId))
      .then(() => {
        resolve({ status: true });
        fireLogEvent(COURSE_EVENTS?.COURSE, {
          type: COURSE_EVENTS?.COURSE_REMOVED,
        });
      })
      .catch((error) => reject({ status: false, error: error?.message }));
  });

// Delete Story Item - set deleteDocument flag to true
export const deleteStoryItem = (storyItemId, collectionName) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, storyItemId), {
      deleteDocument: true,
      updatedBy: auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve({ status: true });
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].REMOVED,
        });
      })
      .catch((error) => reject({ status: false, error: error?.message }));
  });

export async function DeleteChatMessages(chatRoomId, setLoading) {
  const chatMessagesRef = collection(
    db,
    'chatRooms',
    chatRoomId,
    'chatMessages'
  );

  //s
  const querySnapshot = await getDocs(chatMessagesRef);

  const updatePromises = querySnapshot.docs.map((docSnapshot) => {
    return updateDoc(docSnapshot.ref, { deleteDocument: true });
  });

  try {
    if (setLoading) setLoading(true);
    await Promise.all(updatePromises);
    console.log('All chat messages updated successfully.');
  } catch (error) {
    console.error('Error updating chat messages:', error);
  } finally {
    if (setLoading) setLoading(false);
  }
}

// Delete College & Requirements - set deleteDocument flag to true
export const deleteCollege = (storyItemId, collectionName) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, storyItemId), {
      deleteDocument: true,
      deletedBy: auth?.currentUser?.uid,
      deletedAt: new Date(),
    })
      .then(() => {
        resolve({ status: true });
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].REMOVED,
        });
      })
      .catch((error) => reject({ status: false, error: error?.message }));
  });

export const removeCollaborator = async (
  collectionName,
  documentId,
  teamMemberId
) => await addCollaborator(collectionName, documentId, teamMemberId, true);
