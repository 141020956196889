export const helperTexts = {
  ADD_COURSE_TOOLTIP:
    'Add a Course Here’s the place to add any coursework that was or is especially compelling to you. Eventually you’ll put all your coursework into your college applications, but for now let’s just capture the classes that make your mind buzz.',
  COURSE_DESCRIPTION_TOOLTIP:
    'Briefly describe this class and give us an idea of what made it compelling to you. Was it the teacher, a particular idea that it sparked, or just a feeling of overall satisfaction that made you love it? Colleges want to know!',
};

const GENERAL_MESSAGE = 'Are you sure you want to delete <item>?';

export const confirmationMessages = {
  COURSE_DELETE: GENERAL_MESSAGE.replace('<item>', 'course'),
};

export const welcomeMessageFromTeamUniversily =
  'Hello Student! Welcome to Universily! If you have any questions or want to provide feedback about Universily, you can reach us through this Chat. Good luck finding your niche and telling your unique story!';

export const welcomeMessageFromAI = `Hello Student, I am Uni, your virtual college counselor. I am here to help you with every step of the college application process. How can I help you today?`;
