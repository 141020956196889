// School Year break items
export const schoolYearBreakItemsKeywords = {
  DURING_SCHOOL_YEAR: 'During school year',
  DURING_SCHOOL_BREAK: 'During school break',
  ALL_YEAR: 'All year',
};

// Term Keywords
export const termItemsKeywords = {
  SUMMER: 'Summer',
  FALL: 'Fall',
  WINTER: 'Winter',
  SPRING: 'Spring',
};

// Years
export const yearKeyword = [9, 10, 11, 12, 'College'];

// Test with Subjects items
export const testWithSubjects = [
  'sat-subject',
  'ap-subject',
  'cambridge',
  'ib-subject',
  'sat',
];

// Profile Items Keywords
export const accountPersonalKeywords = {
  birthDay: 'Birthday',
  gender: 'Gender',
  firstName: 'First Name',
  lastName: 'Last Name',
};

// User Types Keywords
export const userTypeKeywords = {
  ADMINISTRATOR: 'Administrator',
  STUDENT: 'Student',
  FAMILY: 'Family',
  TEACHER: 'Teacher/Coach',
  SCHOOL_COUNSELOR: 'School Counselor',
  PRIVATE_COUNSELOR: 'Private Counselor',
  UNIVERSILY: 'Universily',
};

export const userTypeIDs = {
  ADMINISTRATOR: 'Administrator',
  STUDENT: 1,
  FAMILY: 2,
  TEACHER: 3,
  SCHOOL_COUNSELOR: 4,
  PRIVATE_COUNSELOR: 5,
  UNIVERSILY: 'Universily',
};

// Page Guidance Keywords
export const pageGuidance = {
  MY_APPLICATION: [
    {
      index: 0,
      name: 'generalInformation',
    },
    {
      index: 1,
      name: 'preview',
    },
    {
      index: 2,
      name: 'schoolReport',
    },
    {
      index: 3,
      name: 'tests',
    },
    {
      index: 4,
      name: 'essays',
    },
    {
      index: 5,
      name: 'supplementalInformation',
    },
  ],
  APPLICATION_REQUIREMENTS: [
    {
      index: 0,
      name: 'app-requirements-generalInformation',
    },
    {
      index: 1,
      name: 'app-requirements-preview',
    },
    {
      index: 2,
      name: 'app-requirements-schoolReport',
    },
    {
      index: 3,
      name: 'app-requirements-tests',
    },
    {
      index: 4,
      name: 'app-requirements-essays',
    },
    {
      index: 5,
      name: 'app-requirements-supplementalInformation',
    },
  ],
};

// Application Comments Types
export const applicationCommentsType = {
  APPLICATION_GENERAL: 'Application: ',
  APPLICATION_PREVIEW: 'Application Preview: ',
  APPLICATION_SCHOOL_REPORT: 'Application School Report: ',
  APPLICATION_TESTS: 'Application Tests: ',
  APPLICATION_ESSAY: 'Application Essays: ',
  APPLICATION_SUPPLEMENTAL_INFO: 'Application Supplementals: ',
};

// Section Header Sub titles
export const sectionHeaderSubTitles = {
  // MY STORY
  MY_STORY: 'Build and tell your story',
  ACTIVITIES_DESC: 'Manage all your activities and honors in one place',
  COURSEWORK_DESC: 'Your classes and grades',
  ESSAY_DESC: 'Essay templates to be used in your applications',
  NICHE_DESC: 'What is unique about you?',
  PROFILE_DESC: 'Your background information',
  TEST_DESC: 'Standardized tests',

  // MY TEAM
  MY_TEAM_DESC: 'The People Helping Tell Your Story',
  TEAM_MEMBERS_DESC: 'Your family and counselors',

  // APPLICATIONS
  APPLICATION_DESC: 'Define and complete your applications',
  COLLEGES_DESC: 'Potential colleges that interest you',
  MY_APPLICATION_DESC: 'Assemble Your Story into college applications',
  TEMPLATE_DESC: 'Track the requirements for each college application',
  ROUND_DESC: 'Your strategy for when to apply to each college',

  // ACCOUNT
  PERSONAL_DESC: 'Your account information',
  CONTACT_DESC: 'Contact information',
  SUBSCRIPTION_DESC: 'Account subscription and billing',
  INVITE_FRIENDS_DESC: 'Refer Universily to your friends',
  PROFILE_LOGIN_DESC: 'Track user login history',
  COLLEGES_LIST: 'College list',
  UNIVERSILY_TEMPLATES_DESC: 'Universily Templates',
};

// College Modal Keywords
export const collegePageNumeric = {
  averageGPA: 'Average GPA',
  averageSTA: 'Average SAT',
  acceptanceRate: 'Acceptance Rate',
};

export const applicationItemStatus = {
  completed: 'Completed',
  notStarted: 'Not Started',
  underway: 'Underway',
  accepted: 'Accepted',
  waitlisted: 'Waitlisted',
  rejected: 'Rejected',
  withdrawn: 'Withdrawn',
  pending: 'Pending',
};

export const excelRoundMapper = {
  ED: 'early-decision-I',
  EDII: 'early-decision-II',
  EA: 'early-action-not-restricted-I',
  EAII: 'early-action-not-restricted-II',
  REA: 'early-action-restricted-I',
  Rolling: 'rolling',
  'RD/Rolling': 'regular',
};

export const googleDriveFolders = {
  UNIVERSILY: 'Universily',
  HONOR: 'Honor',
  ACTIVITY: 'Activity',
  ESSAY: 'Essay',
};

const baseMime = 'application/vnd.google-apps';

export const googleDriveMimeTypes = {
  FOLDER: baseMime + '.folder',
  GOOGLE_DOC: baseMime + '.document',
};

export const collegeWebsitePlatformKey = {
  key: 'college-website',
  label: 'College Website',
};
export const slideroomPlatformKey = { key: 'slideRoom', label: 'Slide Room' };
export const otherPlatformKey = { key: 'other', label: 'Other' };

export const optionalKey = 'optional';
export const requiredKey = 'required';

export const INVITE_KEYS = {
  STUDENT: 'student',
};

export const AI_TEMPLATES = {
  INTRO_PROMPT: 'AI-Prompt-General-Introduction-Public',
  NICHE_PROMPT: 'AI-Prompt-Suggest-Niche',
  ACTIVITY_SUGGEST_PROMPT: 'AI-Prompt-Suggest-Activities',
  ACTIVITY_NEARBY_SUGGEST_PROMPT: 'AI-Prompt-Suggest-Nearby-Activities',
  HONOR_SUGGEST_PROMPT: 'AI-Prompt-Suggest-Honors',
  COURSE_SUGGEST_PROMPT: 'AI-Prompt-Suggest-Courses',
  MY_HUB_PROMPT: 'AI-Prompt-myHub-Start',
  ESSAY_PROMPT: 'AI-Prompt-Essay-Start',
  APPLICATION_PROMPT: 'AI-Prompt-Application-Start',
  APPLICATION_SUGGEST_COLLEGE_PROMPT: 'AI-Prompt-Suggest-Colleges',
  APPLICATION_CHANGE_ME_PROMPT: 'AI-Prompt-Application-ChanceMe',
};
