import { ThemeProvider } from '@mui/system';
import { Alert as ALERT } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { ReactComponent as InfoIcon } from '../../assets/svgs/info.svg';
import { ReactComponent as SuccessIcon } from '../../assets/svgs/success.svg';
import { ReactComponent as WarningIcon } from '../../assets/svgs/warning.svg';
import { ReactComponent as ErrorIcon } from '../../assets/svgs/error.svg';

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#eef4ee',
        },
        standardError: {
          backgroundColor: '#fbeded',
        },
        standardWarning: {
          backgroundColor: '#fdf0e6',
        },
        standardInfo: {
          backgroundColor: '#f0f2f8',
        },
      },
    },
  },
});

const StyledAlert = styled(ALERT)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  borderRadius: 8,
  padding: '8px 12px',
  minHeight: 40,
  boxShadow:
    '0.5px 0px 2px rgba(0, 0, 0, 0.05), 0px 0.5px 2px rgba(0, 0, 0, 0.05), 0px 0.5px 2px rgba(0, 0, 0, 0.05)',
  '.MuiAlert-message': {
    padding: 0,
    color: '#22252D',
    opacity: 0.7,
    fontSize: 13,
    lineHeight: '24px',
  },
  '.MuiAlert-icon': {
    margin: 0,
    padding: 0,
    color: '#22252D',
    opacity: 0.7,
  },
}));

const Alert = ({ children, severity = 'info', ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledAlert
        {...props}
        severity={severity}
        iconMapping={{
          info: <InfoIcon />,
          success: <SuccessIcon />,
          warning: <WarningIcon />,
          error: <ErrorIcon className="rotate-180" />,
        }}
      >
        {children}
      </StyledAlert>
    </ThemeProvider>
  );
};

export default Alert;
