import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';
import useAuth from './use-auth';

const useTeams = (teamList) => {
  const [teams, setTeams] = useState([]);
  const [isFetching, setIsFetching] = useState(true); // State for loading indicator

  const { user } = useAuth(); // Custom hook to get current user
  const userId = user?.uid; // Get userId

  useEffect(() => {
    if (!userId) {
      setIsFetching(false); // If no user, stop fetching
      return;
    }

    setIsFetching(true); // Set fetching state to true at the beginning

    // Reference to user's profile document
    const userProfileRef = doc(db, 'profile', userId);
    let unsubscribeProfile = () => {}; // Initialize an empty function for cleanup
    let unsubscribeTeams = []; // Array to hold unsubscribe functions for team listeners

    unsubscribeProfile = onSnapshot(userProfileRef, (profileDoc) => {
      if (profileDoc.exists()) {
        const userTeams =
          profileDoc.data().teams?.map((team) => team?.teamId) || []; // Get user's teamIds

        // Cleanup previous team listeners if they exist
        unsubscribeTeams.forEach((unsub) => unsub());

        // For each teamId, set up a listener to monitor the team document
        unsubscribeTeams = userTeams.map((teamId) => {
          const teamDocRef = doc(db, 'teams', teamId);
          return onSnapshot(teamDocRef, (teamDoc) => {
            if (teamDoc.exists()) {
              // Transform the team document into the required structure
              const teamData = transformTeamData(teamDoc);
              setTeams((prevTeams) => {
                const existingTeamIndex = prevTeams.findIndex(
                  (team) => team.id === teamData.id
                );
                if (existingTeamIndex !== -1) {
                  // If team already exists in state, update it
                  const updatedTeams = [...prevTeams];
                  updatedTeams[existingTeamIndex] = teamData;
                  return updatedTeams;
                } else {
                  // Add new team to state
                  return [...prevTeams, teamData];
                }
              });
            }
          });
        });

        setIsFetching(false); // Set fetching state to false after setting up all listeners
      } else {
        setTeams([]);
        setIsFetching(false); // Set fetching state to false if no profile document exists
      }
    });

    // Cleanup on unmount or dependency change
    return () => {
      unsubscribeProfile(); // Cleanup profile listener
      unsubscribeTeams.forEach((unsub) => unsub()); // Cleanup team listeners
    };
  }, [userId, teamList]);

  return { teams, isFetching };
};

// Function to transform the team data to the required structure
const transformTeamData = (teamDoc) => {
  const teamData = teamDoc.data();
  return {
    id: teamDoc.id, // Team document ID
    teamName: teamData.teamName || '',
    createdBy: teamData.createdBy || '',
    createdAt: teamData.createdAt || null,
    studentEmail: teamData.studentEmail || '',
    sampleDataCreated: teamData.sampleDataCreated || false,
    teamMembers:
      teamData.teamMembers?.map((member) => ({
        uid: member.uid || '',
        role: member.role || '',
        email: member.email || '',
        invite_status: member.invite_status || '',
        dateAdded: member.dateAdded || null,
        invitedBy: member.invitedBy || null, // optional, included if exists
      })) || [],
  };
};

export default useTeams;
