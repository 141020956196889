import { addStoryItemTypes, googleDriveFolders } from '../constants/other';
import {
  collegesSampleData,
  essaysSampleData,
  myStorySampleData,
  nicheSampleData,
} from '../constants/sampleData';
import { addStoryItem } from '../firebase/services/addServices';
import { useGoogleDocs } from '../hooks';
import { ASK_UNI_DETAILS, TEAM_UNIVERSILY_DETAILS } from './utlityTypes';

// const googleDocs = useGoogleDocs();
// Parameters may be declared in a variety of syntactic forms
/**
 * @param {string}  createdBy - A string param Uid.
 * @param {string}  owner - A string param.
 * @param {string} sharedWith - A string param.
 */
export async function createSampleData({ createdBy, owner, sharedWith }) {
  try {
    // first create niche data
    const nicheId = await createNicheData(createdBy, owner, sharedWith);

    // create college data
    await createCollegeData(createdBy, owner, sharedWith);

    // create activity data
    await createActivityData(createdBy, owner, nicheId, sharedWith);

    // create honors data
    await createHonorsData(createdBy, owner, nicheId, sharedWith);

    // create course data
    await createCourseData(createdBy, owner, nicheId, sharedWith);

    // create test scores data
    await createTestScoresData(createdBy, owner, sharedWith);

    // create GPA data
    await createGPAData(createdBy, owner, sharedWith);

    // create essays data
    await createEssayData(createdBy, owner, sharedWith);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function createNicheData(createdBy, owner, sharedWith) {
  // Creating a niche from student
  const studentNicheData = {
    createdBy,
    owner,
    nicheName: nicheSampleData.name,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentNicheData,
    addStoryItemTypes.NICHE
  );

  if (utilityId) {
    createChatRoom(utilityId, nicheSampleData.name, 'Niche', createdBy);
  }

  return utilityId;
}

async function createCollegeData(createdBy, owner, sharedWith) {
  // Creating a college from student
  const studentCollegeData = {
    createdBy,
    owner,
    collegeId: collegesSampleData.collegeId,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentCollegeData,
    addStoryItemTypes.APPLICATIONS
  );

  if (utilityId) {
    createChatRoom(
      utilityId,
      collegesSampleData.name,
      'Application',
      createdBy
    );
  }

  return utilityId;
}

async function createActivityData(createdBy, owner, nicheId, sharedWith) {
  // Creating a activity from student
  const studentActivityData = {
    createdBy,
    owner,
    nicheId,
    ...myStorySampleData.Activity,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentActivityData,
    addStoryItemTypes.ACTIVITY
  );

  if (utilityId) {
    createChatRoom(
      utilityId,
      myStorySampleData.Activity.activityName,
      'Activity',
      createdBy
    );
  }

  return utilityId;
}

async function createHonorsData(createdBy, owner, nicheId, sharedWith) {
  // Creating a honors from student
  const studentHonorsData = {
    createdBy,
    owner,
    nicheId,
    ...myStorySampleData.Honors,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentHonorsData,
    addStoryItemTypes.HONOR
  );

  if (utilityId) {
    createChatRoom(
      utilityId,
      myStorySampleData.Honors.honorName,
      'Honors',
      createdBy
    );
  }

  return utilityId;
}

async function createCourseData(createdBy, owner, nicheId, sharedWith) {
  // Creating a course from student
  const studentCourseData = {
    createdBy,
    owner,
    nicheId,
    ...myStorySampleData.Course,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentCourseData,
    addStoryItemTypes.COURSE
  );

  if (utilityId) {
    createChatRoom(
      utilityId,
      myStorySampleData.Course.courseName,
      'Course',
      createdBy
    );
  }
}

async function createTestScoresData(createdBy, owner, sharedWith) {
  // Creating a test scores from student
  const studentTargetsData = {
    createdBy,
    owner,
    targets: myStorySampleData.Tests.targets,
    testType: myStorySampleData.Tests.testType,
    sharedWith: sharedWith ? [sharedWith] : [],
  };

  const studentTestScoresData = {
    createdBy,
    owner,
    scores: myStorySampleData.Tests.scores,
    testType: myStorySampleData.Tests.testType,
    testDate: myStorySampleData.Tests.testDate,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const targetUtilityId = await addStoryItem(
    studentTargetsData,
    addStoryItemTypes.TEST_TARGETS
  );
  const scoreUtilityId = await addStoryItem(
    studentTestScoresData,
    addStoryItemTypes.TEST_SCORE
  );

  if (scoreUtilityId) {
    createChatRoom(scoreUtilityId, 'SAT', 'Tests', createdBy);
  }

  return scoreUtilityId;
}

async function createGPAData(createdBy, owner, sharedWith) {
  // Creating a GPA from student
  const studentGPAData = {
    createdBy,
    owner,
    GPAData: myStorySampleData.GPA.GPAData,
    RankData: myStorySampleData.GPA.RankData,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentGPAData,
    addStoryItemTypes.GPA_SCORES
  );

  if (utilityId) {
    createChatRoom(utilityId, 'GPA', 'GPA', createdBy);
  }

  return utilityId;
}

async function createEssayData(createdBy, owner, sharedWith) {
  // Creating a essay from student
  const studentEssayData = {
    createdBy,
    owner,
    ...essaysSampleData,
    sharedWith: sharedWith ? [sharedWith] : [],
  };
  const utilityId = await addStoryItem(
    studentEssayData,
    addStoryItemTypes.ESSAY
  );

  if (utilityId) {
    createChatRoom(utilityId, essaysSampleData.essayName, 'Essay', createdBy);
  }

  return utilityId;
}

// Parameters may be declared in a variety of syntactic forms
/**
 * @param {string}  utilityId - A string param.
 * @param {string}  topicName - A string param.
 * @param {string}  topicType - A string.
 * @param {string}  createdBy - A string param.
 */

async function createChatRoom(utilityId, topicName, topicType, createdBy) {
  try {
    await addStoryItem(
      {
        isTopic: true,
        chatParticipants: [
          createdBy,
          ASK_UNI_DETAILS.uid,
          TEAM_UNIVERSILY_DETAILS.uid,
        ],
        topicName: 'Topic for ' + topicName,
        utilityId: utilityId,
        topicType: topicType,
      },
      addStoryItemTypes.CHAT_ROOM
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
