import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const NicheTabBLock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '85px',
  padding: '0px 0px 0px 60px',
  marginRight: '-24px',
  [theme.breakpoints.down('md')]: {
    padding: '35px 0px 10px 40px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '100px 0px 0px 0px',
    display: 'block',
    marginRight: '0px',
  },
}));

export const NicheTabBLockLeft = styled('div')(({ theme }) => ({
  width: '50%',
  position: 'relative',
  // paddingRight: '340px',
  paddingBottom: '30px',
  // [theme.breakpoints.down('xl')]: {
  //   paddingRight: '100px',
  // },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0px 0px 100px 0px',
    textAlign: 'center',
  },
  h2: {
    fontSize: '60px',
    color: '#2C303C',
    lineHeight: '85px',
    fontWeight: '500',
    paddingBottom: '20px',
    position: 'relative',
    width: '490px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '50px',
      lineHeight: '75px',
      width: '410px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '40px',
      lineHeight: '60px',
      width: '330px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '30px',
      lineHeight: '50px',
      width: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      lineHeight: '32px',
      width: '260px',
      margin: '0 auto',
      display: 'table',
      br: {
        display: 'none',
      },
    },

    '.top-title-shape': {
      top: '-28px',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        top: '-15px',
        width: '25px',
      },
    },
    '.bottom-title-shape': {
      bottom: '6px',
      position: 'absolute',
      right: '0px',
      [theme.breakpoints.down('md')]: {
        width: '94px',
      },
    },
  },
  '.disc-block': {
    fontSize: '18px',
    lineHeight: '30px',
    color: '#2C303C',
    fontWeight: '400',
    marginBottom: '30px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
      lineHeight: '26px',
      marginBottom: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '23px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '23px',
      padding: '0px 15px',
      br: {
        display: 'none',
      },
    },
  },
}));

export const NicheTabBLockRight = styled('div')(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    position: 'relative',
    left: '24px',
  },
  img: {
    width: '100%',
  },
}));
export const CommonThemeButton = styled(Button)(({ theme }) => ({
  borderRadius: '16px',
  padding: '16px 23px 16px 19px',
  textTransform: 'capitalize',
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px',
    padding: '10px 19px 10px 16px',
  },
  p: {
    fontSize: '18px',
    lineHeight: '16px',
    fontWeight: '400',
    paddingLeft: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      paddingLeft: '7px',
    },
  },
}));
