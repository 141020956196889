import { styled } from '@mui/system';

import { Card, CardActionArea, CardContent } from '@mui/material';

export const ActivitiesTabs = styled('div')(({ theme }) => ({
  padding: '0px 64px',
  display: 'flex',
  margin: '0px -12px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    padding: '0px 44px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}));

export const ActivitiesTabsInner = styled('div')(({ theme }) => ({
  width: '31.4%',
  padding: '0px 12px',
  marginBottom: '24px',
  [theme.breakpoints.down('xl')]: {
    width: '31%',
  },
  [theme.breakpoints.down('lg')]: {
    width: '46%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const CustomCard = styled(Card)(({ theme }) => ({
  borderRadius: '8px',
  boxShadow: '0px 2px 4px 1px #E0E0E0',
  '&:hover': {
    backgroundColor: '#fff',
    boxShadow:
      ' 0px 2px 4px -1px rgba(174, 174, 174, 0.2), 0px 6px 12px rgba(158, 158, 158, 0.14), 0px 6px 10px rgba(116, 116, 116, 0.12)',
  },
}));

export const CustomCardActionArea = styled(CardActionArea)(({ theme }) => ({
  backgroundColor: '#fff',
}));

export const CustomCardContent = styled(CardContent)(({ theme }) => ({
  padding: '0px',
}));

export const TopBlockCard = styled('div')(({ theme }) => ({
  padding: '19px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #EAEAEB',
  [theme.breakpoints.down('md')]: {
    padding: '19px 16px',
  },
}));

export const TopBlockCardInner = styled('div')(({ theme }) => ({
  padding: '9px 12px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  borderRadius: '24px',
  img: {
    width: '16px',
  },
  p: {
    fontWeight: '400',
    fontSize: '13px',
    paddingLeft: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.46px',
  },
}));
export const LeftBLockCardInner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.MuiBadge-root': {
    marginRight: '25px',
    '.MuiBadge-standard': {
      width: '18px',
      minWidth: '18px',
      height: '18px',
      fontSize: '11px',
      border: '2px solid #FFFFFF',
      top: '2px',
      right: '3px',
    },
  },
  '.MuiSvgIcon-root': {
    fill: '#757575',
  },
}));

export const VerisonDetails = styled('div')(({ theme }) => ({
  h5: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#868686',
    marginBottom: '10px',
  },
}));

export const VerisonDetailsInner = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const VerisonDetailsBLock = styled('div')(({ theme }) => ({
  h3: {
    fontWeight: '500',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: '8px',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  p: {
    fontWeight: '400',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
}));

export const BottomBLock = styled('div')(({ theme }) => ({
  padding: '16px 24px',
  fontFamily: "'Roboto',sans-serif",
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
  h2: {
    fontSize: '24px',
    letterSpacing: '0.2px',
    fontWeight: '500',
    marginBottom: '16px',
    fontFamily: "'Roboto',sans-serif",
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },
}));

export const FooterBLock = styled('div')(({ theme }) => ({
  padding: '16px 24px',
  borderTop: '1px solid #EAEAEB',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
  h3: {
    fontWeight: '500',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: '0px',
    letterSpacing: '0.1px',
  },
  '.MuiAvatarGroup-root': {
    '.MuiAvatar-root': {
      width: '33px',
      height: '32px',
      fontWeight: '300',
      fontSize: '14px',
      color: '#1E1E1E',
    },
  },
}));

export const FooterBLockInner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
  h4: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#868686',
    marginBottom: '8px',
    letterSpacing: '0.1px',
  },
}));
