import { Snackbar as SNACKBAR } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as SuccessCheckIcon } from '../../assets/svgs/SuccessCheckIcon.svg';
import { ReactComponent as AlertIcon } from '../../assets/svgs/AlertIcon.svg';

const StyledSnackbar = styled(SNACKBAR)(() => ({
  boxShadow:
    '1px 4px 6px rgba(0, 0, 0, 0.05), 1px 4px 6px rgba(0, 0, 0, 0.05), 1px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05)',
  backgroundColor: '#2B2F38',
  borderRadius: 8,
}));

const SuccessSnackbar = ({
  autoHideDuration = 5000,
  message,
  actionName,
  alert = false,
  onAction = () => {},
  ...props
}) => {
  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      {...props}
    >
      <div className="flex gap-3 items-center h-12 px-4 rounded-lg">
        {alert ? <AlertIcon /> : <SuccessCheckIcon />}
        <div className="text-white text-sm">{message}</div>
        {actionName && (
          <div
            className="cursor-pointer text-white text-sm opacity-70 underline underline-offset-4 hover:opacity-30"
            onClick={onAction}
          >
            {actionName}
          </div>
        )}
      </div>
    </StyledSnackbar>
  );
};

export default SuccessSnackbar;
