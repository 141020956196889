import { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PopupButton } from './PopupButton';
import Button from './Button';
import { InputField } from './InputField';

export const UploadLinkDialog = ({
  buttonText = '',
  link = '',
  onSave = () => {},
  startIcon = <AddCircleIcon />,
  ...props
}) => {
  const [anchor, setAnchor] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (link) {
      setInputValue(link);
    }
  }, [link]);

  const openMenu = (e) => {
    setAnchor(e.currentTarget);
    setIsMenuOpen(true);
  };

  const closeMenu = (e) => {
    setAnchor(null);
    setIsMenuOpen(false);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <PopupButton
      buttonText={buttonText}
      startIcon={startIcon}
      anchorEl={anchor}
      open={isMenuOpen}
      onClose={closeMenu}
      onClick={openMenu}
      sx={{
        flexDirection: 'row !important',
      }}
      {...props}
    >
      <div className="flex flex-col gap-6 w-full">
        <InputField
          label="Link to High School Counselor system"
          value={inputValue}
          className="w-96"
          placeholder="Usually Naviance or SCOIR"
          onChange={(e) => {
            e.stopPropagation();
            handleChange(e);
          }}
        />
        <Divider />
        <div className="flex gap-2 ml-auto">
          <Button variant="text" onClick={closeMenu}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onSave(inputValue);
              closeMenu();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </PopupButton>
  );
};
