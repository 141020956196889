import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, Divider, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import { useState } from 'react';
import Tooltip from '../shared/Tooltip';
import { DeleteChatMessages } from '../../firebase/services/deleteServices';
import GroupAvatar from './GroupAvatar';

const ChatBodyHeader = ({
  topicName = '',
  onChatBack = () => {},
  menuItems = [],
  openChatType = '',
  receipientDetails = {},
  allParticipantsDetails = [],
  openChatId = '',
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [isChatDeleteLoading, setIsChatDeleteLoading] = useState(false);

  return (
    <div className="flex flex-col px-6">
      <div className="flex items-center gap-3 py-4 ">
        <Tooltip title="Back">
          <div>
            <IconButton onClick={onChatBack}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </Tooltip>
        {openChatType === 'groupChats' ? 
          <Tooltip
            title={topicName || ''}
          >
            <GroupAvatar participants={allParticipantsDetails} size={30}/>
          </Tooltip>
        : <Tooltip
          title={`${receipientDetails?.firstName || ''} ${
            receipientDetails?.lastName || ''
          }`}
        >
          <Avatar
            sx={{
              height: '25px',
              width: '25px',
            }}
            src={receipientDetails?.photoURL}
          />
        </Tooltip> }
        <Tooltip title={topicName || ''}>
          <div className="ellipsis w-fit max-w-full">{topicName || ''}</div>
        </Tooltip>
        {receipientDetails?.email === 'askuni@universily.com' ? (
          <Tooltip title={'Clear All Chat'}>
            {isChatDeleteLoading ? (
              <div
                className="spinner-border animate-spin ml-auto inline-block w-4 h-4 border-4 rounded-full border-t-brand-blue-500"
                role="status"
              ></div>
            ) : (
              <div
                className="ellipsis w-fit max-w-full cursor-pointer text-brand-blue-500 text-xs font-roboto font-medium ml-auto"
                onClick={() =>
                  DeleteChatMessages(openChatId, setIsChatDeleteLoading)
                }
              >
                Clear Chat
              </div>
            )}
          </Tooltip>
        ) : null}
      </div>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          borderColor: '#616675',
          opacity: '20%',
        }}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {menuItems?.map((item) => (
          <MenuItem
            onClick={item.onClick}
            disabled={item.disabled}
            key={item?.id}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ChatBodyHeader;
