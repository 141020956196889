import { ReactComponent as CalendarIcon } from '../../assets/svgs/calendar.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';

export const SelectDateInput = ({
  label = 'Select Date',
  value = '',
  background = 'white',
  ...props
}) => {
  const [formattedDate, setFormattedDate] = useState('');
  const getFormattedDate = () => {
    if (value) {
      const date = new Date(value);
      const monthName = date.toLocaleString('en-US', { month: 'short' });
      const selectedDate = date.getDate();
      const selectedYear = date.getFullYear();
      setFormattedDate(monthName + ' ' + selectedDate + ', ' + selectedYear);
    }
  };

  useEffect(() => {
    if (value) {
      getFormattedDate();
    } else {
      setFormattedDate('');
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        renderInput={(params) => (
          <div ref={params.inputRef} className="inline-block w-full">
            <TextField
              label={label}
              variant="outlined"
              placeholder="Select Date"
              className="w-full"
              value={formattedDate}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" className="cursor-pointer">
                    {
                      <CalendarIcon
                        style={{
                          fill: '#616675',
                        }}
                      />
                    }
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  background: background,
                  paddingRight: '4px',
                },
              }}
              onClick={() =>
                params.InputProps.endAdornment.props.children.props.onClick()
              }
            />
          </div>
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};
