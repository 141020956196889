import { AccordionSummary, IconButton, Typography } from '@mui/material';
import { Accordion } from '../shared/Accordion';
import { StatusIndicator } from '../shared/StatusIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '../shared/Alert';
import useThemeStore from '../../stores/ThemeStore';

export const ApplicationAccordion = ({
  title = '',
  status = '',
  alert = '',
  children,
  alertType = '',
  ...props
}) => {
  const { isMobile } = useThemeStore();
  return (
    <Accordion
      isMobile={isMobile}
      header={
        <AccordionSummary
          expandIcon={
            <IconButton aria-label="Accordion">
              <ExpandMoreIcon />
            </IconButton>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: 0,
          }}
        >
          <div className="flex gap-6 w-full">
            <StatusIndicator status={status} />
            <Typography className="w-30 flex items-center">{title}</Typography>
            {alert && (
              <div className="w-70">
                <Alert severity={alertType} icon={false}>
                  {alert}
                </Alert>
              </div>
            )}
          </div>
        </AccordionSummary>
      }
      children={
        <div className="flex gap-10 sm:gap-20">
          <div className="px-1.5"></div>
          {children}
        </div>
      }
      {...props}
    />
  );
};
