import { Dialog as DIALOG } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import Button from './Button';
import { styled } from '@mui/system';
import useEssayStore from '../../stores/EssayStore';
import useThemeStore from '../../stores/ThemeStore';
import LoadingButton from './LoadingButton';

const StyledDialog = styled(DIALOG)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: '0px 24px',
  },
}));

const Dialog = ({
  open = false,
  isError = false,
  title = '',
  maxWidth = 'sm',
  fullWidth = true,
  showFooter = true,
  editMode = false,
  onCancel = () => {},
  cancelText = 'Cancel',
  onContinue = () => {},
  continueText = 'Continue',
  children,
  isDelete,
  isHeight = false,
  isBackgroundWhite = true,
  disabled = false,
  isLoading = false,
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const essayStore = useEssayStore();
  const { isMobile } = useThemeStore();
  return (
    <StyledDialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          height: isHeight
            ? '100%'
            : isMobile && isHeight
            ? '100%'
            : isMobile && !isHeight
            ? '100%'
            : 'fit-content',
          overflow: isMobile ? 'scroll' : '',
        },
      }}
      {...props}
      open={open}
    >
      <div className={`${!showFooter && 'pb-6'} flex flex-col flex-1 gap-4`}>
        <div
          className={`${
            isBackgroundWhite ? 'bg-white' : 'bg-cream-75'
          } flex justify-between items-center pt-6 top-0 sticky z-50`}
        >
          <div className="text-2xl text-grey-900 font-semibold font-archivo ellipsis max-w-6">
            {title}
          </div>
          <CloseIcon
            className="cursor-pointer text-[#757575]"
            onClick={() => {
              onCancel();
              if (essayStore.isAddingEssay && !essayStore.isAddingCoreEssay) {
                essayStore.setIsAddingEssay(false);
              }
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex-1 overflow-auto py-4">{children}</div>
        </div>
        {showFooter && (
          <div className="sticky bottom-0 bg-white h-20">
            <Divider />
            <div className="flex justify-end gap-2 pt-6 z-10">
              <Button variant="text" onClick={onCancel}>
                {cancelText}
              </Button>
              <LoadingButton
                loading={isLoading}
                className={`${isDelete ? 'bg-alert-error' : ''}`}
                onClick={(e) => onContinue(e)}
                disabled={isError || disabled}
              >
                {continueText}
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

export default Dialog;
