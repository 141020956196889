export const toastTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const toastMessages = {
  // DASHBOARD

  // MY STORY

  // COURSES
  COURSE_ADD_SUCCESS: 'Course added successfully',
  COURSE_UPDATE_SUCCESS: 'Course updated successfully',
  COURSE_DELETE_SUCCESS: 'Course deleted successfully',

  // MISC
  GENERAL_ERROR: 'Oops something went wrong',
  RECOMMENDATION_LETTERS: 'Recommendation letters details have been',
  TRANSCRIPTS: 'Transcripts details have been',
  INTERVIEW: 'Interview details have been',
  HIGHSCHOOL_LINK: 'High School link',
  COLLEGE_ADDED: 'Awesome! You have added your first college.',
  APPLICATION_ADDED: 'You have added another college for your application.',
};
