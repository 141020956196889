import { useEffect, useState } from 'react';
import { Divider, IconButton } from '@mui/material';
import { ApplicationAccordion } from './ApplicationAccordion';
import Button from '../shared/Button';
import { MyApplicationsUtilityDetails } from './MyApplicationsUtilityDetails';
import { AddLetterDetails } from './addRequirements/AddLetterDetails';
import { ApplicationStatus } from './ApplicationStatus';
import { optionalKey, requiredKey } from '../../constants/keywords';
import { selectOptionData } from '../../constants/other';
import { UploadLinkDialog } from '../shared/UploadLinkDialog';
import { Edit, LaunchRounded } from '@mui/icons-material';
import {
  capitalizeFirstLetter,
  getApplicationsUtilityStatus,
  getFormattedDate,
} from '../../utils/helper';
import { ReactComponent as ApplicationInterviewIcon } from '../../assets/svgs/applicationInterview.svg';

const submittedStatus = 'let033';
export function generateModifiedData(data, lettersData) {
  const result = [];
  data?.forEach((item) => {
    for (let i = 1; i <= parseInt(item.count); i++) {
      const newObj = {
        id: `${item.id}${i}`,
        status:
          lettersData?.find((letter) => letter.id === `${item.id}${i}`)
            ?.status || 'Not Yet Requested',
        refId: item.id,
      };
      result.push(newObj);
    }
  });
  return result;
}
export const MyApplicationLetters = ({
  options = [],
  letters = [],
  lettersData = [],
  addApplicationDetails = () => {},
  addHighSchoolLink = () => {},
  profileData = {},
  markedAsSubmitted = false,
  isMobile = false,
  isParent = false,
  ...props
}) => {
  const [letterStatus, setLetterStatus] = useState([]);

  useEffect(() => {
    if (lettersData.length > 0) {
      setLetterStatus(lettersData);
    }
  }, [lettersData]);

  const mandatoryLetters = letters.filter(
    (letter) =>
      letter.requiredOrOptional ===
        selectOptionData.find((tag) => tag.value === requiredKey)?.id ||
      letter.requiredOrOptional === 'required'
  );

  const optionalLetters = letters.filter(
    (letter) =>
      letter.requiredOrOptional ===
        selectOptionData.find((tag) => tag.value === optionalKey)?.id ||
      letter.requiredOrOptional === 'optional'
  );

  const getLetterStatus = (letter, count) => {
    const status = options.find(
      (status) =>
        status.label ===
        letterStatus.find((item) => item?.id === letter?.id + count)?.status
    )?.id;
    if (status) return status;
    return 'let011';
  };

  const updateStatusForUtility = (
    refId,
    letterCount,
    status,
    writerDetails,
    action
  ) => {
    const letters = letterStatus;
    const letterIndex = refId + letterCount;
    const index = letters.findIndex((item) => item.id === letterIndex);
    if (status) {
      const statusLabel = options.find(
        (statusOption) => statusOption.id === status
      )?.label;
      if (index >= 0) {
        letters[index].status = statusLabel;
      } else {
        letters.push({ id: letterIndex, refId, status: statusLabel });
      }
      setLetterStatus(letters);
    } else if (writerDetails) {
      if (index >= 0) {
        letters[index].writer = writerDetails?.writer;
        letters[index].date = writerDetails?.date;
      } else {
        letters.push({
          id: letterIndex,
          refId: refId,
          status: letters[index]?.status || 'Requested',
          ...writerDetails,
        });
      }
      setLetterStatus(letters);
    }
    if (action) {
      addApplicationDetails({ recLetters: letters }, 'letters', action);
    } else {
      addApplicationDetails({ recLetters: letters });
    }
  };

  const getLetterCount = (letters) => {
    let count = 0;
    letters.forEach((letter) => (count += Number(letter?.count) || 0));
    return count;
  };

  const LettersDetails = ({ letter = {}, index = 0 }) => {
    const writer = capitalizeFirstLetter(letter?.writer);
    return (
      <div className="py-6">
        <MyApplicationsUtilityDetails
          title={`By ${writer} - ${index}`}
          utilityColor="#FEC63D"
          rightPanel={
            <LettersRightPanel
              options={options}
              item={letter}
              letterCount={index}
              markedAsSubmitted={markedAsSubmitted}
              letter={letterStatus.find(
                (item) => item?.id === letter?.id + index
              )}
              updateStatusForUtility={updateStatusForUtility}
              getLetterStatus={getLetterStatus}
              isMobile={isMobile}
              isParent={isParent}
            />
          }
          options={options}
        ></MyApplicationsUtilityDetails>
      </div>
    );
  };

  const getLettersCount = (utilityData) => {
    let count = 0;
    if (utilityData) {
      utilityData?.forEach((item) => {
        count = count + Number(item?.count);
      });
    }
    return count;
  };

  const getLettersStatus = () => {
    const notRequired = selectOptionData?.find(
      (option) => option?.value === 'not-accepted'
    )?.id;
    const isStatus = letters?.some(
      (item) =>
        item?.requiredOrOptional === notRequired || !item?.requiredOrOptional
    );

    const modifiedLettersData = generateModifiedData(letters, lettersData);
    if (!isStatus) {
      return getApplicationsUtilityStatus(
        modifiedLettersData,
        getLettersCount(letters)
      );
    }
    return 'not-required';
  };

  return (
    <ApplicationAccordion
      title="Recommendation Letters"
      status={
        markedAsSubmitted
          ? 'completed'
          : letters?.length > 0
          ? getLettersStatus()
          : 'not-required'
      }
      {...props}
    >
      {mandatoryLetters.length > 0 || optionalLetters.length > 0 ? (
        <div
          className="flex flex-col w-2/3 sm:w-full gap-6"
          onClick={(e) => e.stopPropagation()}
        >
          {mandatoryLetters.length > 0 && (
            <div>
              <div className="text-grey-600">
                Mandatory Letters ({getLetterCount(mandatoryLetters)})
              </div>
              {mandatoryLetters.map((letter) => {
                return [...Array(Number(letter?.count)).keys()].map((i) => (
                  <LettersDetails letter={letter} index={i + 1} />
                ));
              })}
              <Divider />
            </div>
          )}
          {optionalLetters.length > 0 && (
            <div>
              <div className="text-grey-600">
                Optional Letters ({getLetterCount(optionalLetters)})
              </div>
              {optionalLetters.map((letter) => {
                return [...Array(Number(letter?.count)).keys()].map((i) => (
                  <LettersDetails letter={letter} index={i + 1} />
                ));
              })}
              <Divider />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <div className="text-grey-700 font-medium">
              Track Recommendation Letters
            </div>
            <div className="flex gap-1 sm:items-center flex-col sm:flex-row">
              <div className="text-sm text-grey-400">
                Request your recommendation letters and track them in your High
                School Counselor's system. Add a link where recommendation
                letters can be requested:
              </div>
              <div className="break-all">
                {profileData?.highschoolLink ? (
                  <>
                    <Button
                      variant="text"
                      color="buttonLink"
                      href={profileData?.highschoolLink}
                      target="_blank"
                      disabled={isMobile}
                      endIcon={
                        <LaunchRounded
                          sx={{
                            height: '14px',
                            width: '14px',
                          }}
                        />
                      }
                      sx={{
                        fontSize: '12px',
                        gap: '0px !important',
                      }}
                    >
                      {profileData?.highschoolLink}
                    </Button>
                    {
                      <IconButton>
                        <UploadLinkDialog
                          link={profileData?.highschoolLink}
                          startIcon={
                            <Edit
                              className="text-cream-450 !hidden sm:!flex"
                              fontSize="small"
                            />
                          }
                          onSave={(data) =>
                            addHighSchoolLink(
                              { highschoolLink: data },
                              'updated'
                            )
                          }
                          sx={{
                            padding: '0px !important',
                            '& .MuiButton-startIcon': {
                              margin: '0px !important',
                            },
                            ':hover': {
                              background: 'none !important',
                            },
                          }}
                        />
                      </IconButton>
                    }
                  </>
                ) : (
                  <UploadLinkDialog
                    disabled={isMobile}
                    buttonText="Add Link here"
                    onSave={(data) =>
                      addHighSchoolLink({ highschoolLink: data }, 'added')
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 py-6 items-center justify-center w-full">
          <ApplicationInterviewIcon />
          <div className="text-grey-700">
            For this college you do not require to submit Recommendation
            Letters.
          </div>
        </div>
      )}
    </ApplicationAccordion>
  );
};

const LettersRightPanel = ({
  options = [],
  item = {},
  updateStatusForUtility = () => {},
  getLetterStatus = () => {},
  letter = {},
  letterCount = 0,
  markedAsSubmitted = false,
  isMobile = false,
  isParent = false,
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-6 sm:w-full">
      <div className="flex items-center sm:w-1/5">
        <ApplicationStatus
          options={options}
          disabled={markedAsSubmitted || isMobile}
          value={
            markedAsSubmitted
              ? submittedStatus
              : getLetterStatus(item, letterCount)
          }
          onChange={(e) =>
            updateStatusForUtility(item?.id, letterCount, e.target.value)
          }
        />
      </div>
      <div className="pl-6 sm:border-l border-grey-100 flex justify-between items-center sm:w-4/5">
        {Object.values(letter)?.length > 0 ? (
          <div className="flex justify-between items-center w-full">
            <div className="flex w-full gap-6">
              <div className="flex flex-col gap-1">
                <div className="text-grey-300 text-xs font-medium">
                  Recommender
                </div>
                <div className="text-grey-700 text-sm">
                  {letter?.writer || '---'}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-grey-300 text-xs font-medium">
                  Requested On
                </div>
                <div className="text-grey-700 text-sm">
                  {getFormattedDate(letter?.date)}
                </div>
              </div>
            </div>
            {
              <AddLetterDetails
                startIcon={
                  <IconButton className="!hidden sm:!flex">
                    <Edit className="text-cream-450" fontSize="small" />
                  </IconButton>
                }
                letter={letter}
                layoutSpread={true}
                isMobile={isMobile}
                onAdd={(formData) =>
                  updateStatusForUtility(
                    item?.id,
                    letterCount,
                    '',
                    formData,
                    'updated'
                  )
                }
                sx={{
                  padding: '0px !important',
                  '& .MuiButton-startIcon': {
                    margin: '0px !important',
                  },
                  ':hover': {
                    background: 'none !important',
                  },
                }}
              />
            }
          </div>
        ) : (
          <AddLetterDetails
            buttonText={`Add Writers Name and When Requested`}
            letter={letter}
            layoutSpread={false}
            isMobile={isMobile}
            onAdd={(formData) =>
              updateStatusForUtility(
                item?.id,
                letterCount,
                '',
                formData,
                'added'
              )
            }
          />
        )}
      </div>
    </div>
  );
};
