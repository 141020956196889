export const MyApplicationsUtilityDetails = ({
  title = '',
  children,
  status = '',
  utilityColor = '',
  rightPanel,
  selectWidth = '1/3',
  options = [],
  onChange = () => {},
  isAdd = '',
  isInterviewLetter = false,
  ...props
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-6">
      <div
        className={`${
          isAdd ? 'w-full' : 'w-full sm:w-30'
        } flex flex-col sm:flex-row  px-3 border-l-2`}
        style={{
          borderColor: utilityColor,
        }}
      >
        <div className="flex w-full flex-col gap-3">
          <div className="text-grey-700 font-medium pr-4 sm:pr-0">{title}</div>
          {children}
        </div>
      </div>
      <div
        className={`${
          isAdd ? 'w-full' : 'w-full sm:w-70'
        } flex flex-col sm:flex-row  gap-6`}
        {...props}
      >
        {rightPanel}
      </div>
    </div>
  );
};
