// Google Analytics Events
export const LOGIN_USER_EMAIL_EVENT = 'login_user_email';
export const SCREEN_VIEW = 'screen_view';
export const PAGE_VIEW = 'page_view';

// Firebase backend calls

export const ACTIVITY_EVENTS = {
  ACTIVITY: 'activity',
  ACTIVITY_CREATED: 'activity_created',
  ACTIVITY_UPDATED: 'activity_updated',
  ACTIVITY_REMOVED: 'activity_removed',
};

export const NICHES_EVENTS = {
  NICHES: 'niches',
  NICHES_CREATED: 'niches_created',
  NICHES_UPDATED: 'niches_updated',
  NICHES_REMOVED: 'niches_removed',
};
export const COURSE_EVENTS = {
  COURSE: 'course',
  COURSE_CREATED: 'course_created',
  COURSE_UPDATED: 'course_updated',
  COURSE_REMOVED: 'course_removed',
};
export const TESTING_TESTS_EVENTS = {
  TESTING_TEST: 'testing_tests',
  TESTING_TEST_CREATED: 'test_created',
  TESTING_TEST_UPDATED: 'test_updated',
  TESTING_TEST_REMOVED: 'test_removed',
};
export const ESSAY_EVENTS = {
  ESSAY: 'essay',
  ESSAY_CREATED: 'essay_created',
  ESSAY_UPDATED: 'essay_updated',
  ESSAY_REMOVED: 'essay_removed',
};
export const HONORS_EVENTS = {
  HONORS: 'honors',
  HONORS_CREATED: 'honors_created',
  HONORS_UPDATED: 'honors_updated',
  HONORS_REMOVED: 'honors_removed',
};

//Application events
export const APPLICATION_EVENTS = {
  APPLICATIONS: 'application',
  APPLICATIONS_CREATED: 'application_created',
  APPLICATIONS_UPDATED: 'application_updated',
  APPLICATIONS_REMOVED: 'application_removed',
};
export const TEMPLATE_EVENTS = {
  TEMPLATE: 'template',
  TEMPLATE_CREATED: 'template_created',
  TEMPLATE_UPDATED: 'template_updated',
  TEMPLATE_REMOVED: 'template_removed',
};

export const ANALYTICS_EVENTS = {
  // TODO Add according to need
  // Course
  COURSE: {
    LABEL: COURSE_EVENTS.COURSE,
    CREATED: COURSE_EVENTS.COURSE_CREATED,
    UPDATED: COURSE_EVENTS.COURSE_UPDATED,
    REMOVED: COURSE_EVENTS.COURSE_REMOVED,
  },

  // Activity
  ACTIVITY: {
    LABEL: ACTIVITY_EVENTS.ACTIVITY,
    CREATED: ACTIVITY_EVENTS.ACTIVITY_CREATED,
    UPDATED: ACTIVITY_EVENTS.ACTIVITY_UPDATED,
    REMOVED: ACTIVITY_EVENTS.ACTIVITY_REMOVED,
  },
};
