import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_VIEW } from '../../constants/firebaseAnalytics';
import { fireLogEvent } from '../../firebase/services/analytics';
import { AI_TEMPLATES } from '../../constants/keywords';
import {
  ACTIVITIES,
  COURSES,
  HONORS,
  MY_COLLEGES,
  MY_ESSAY,
  MY_HUB,
  NICHE,
} from '../../navigation/constants';
import useChatStore from '../../stores/ChatStore';
import useEssayStore from '../../stores/EssayStore';
import useApplicationStore from '../../stores/ApplicationStore';

const PageViewTracker = () => {
  // Hooks
  const location = useLocation();
  const { setPromptType, setPromptStoryType } = useChatStore();
  const essayStore = useEssayStore();
  const applicationStore = useApplicationStore();

  useEffect(() => {
    let loc = location.pathname;
    fireLogEvent(PAGE_VIEW, {
      page_location: window.location.href,
      page_path: loc,
      page_title: document.title,
    });
  }, [location?.pathname]);

  useEffect(() => {
    setPageWisePrompt(location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location?.pathname,
    essayStore.currentSelectedEssay?.id,
    applicationStore?.isCollegeId,
  ]);

  const setPageWisePrompt = (pathname) => {
    let type = AI_TEMPLATES.INTRO_PROMPT;
    let storyType = 'general';
    if (pathname) {
      if (pathname.includes(NICHE)) {
        type = AI_TEMPLATES.NICHE_PROMPT;
        storyType = 'niche';
      }
      if (pathname.includes(ACTIVITIES)) {
        type = AI_TEMPLATES.ACTIVITY_SUGGEST_PROMPT;
        storyType = 'activity';
      }
      if (pathname.includes(HONORS)) {
        type = AI_TEMPLATES.HONOR_SUGGEST_PROMPT;
        storyType = 'honor';
      }
      if (pathname.includes(COURSES)) {
        type = AI_TEMPLATES.COURSE_SUGGEST_PROMPT;
        storyType = 'course';
      }
      if (pathname.includes(MY_HUB)) {
        type = AI_TEMPLATES.MY_HUB_PROMPT;
        storyType = 'myHub';
      }
      if (pathname.includes(MY_ESSAY)) {
        type = AI_TEMPLATES.ESSAY_PROMPT;
        storyType = 'essay';
        console.log(
          'essayStore?.currentSelectedEssay?.id',
          essayStore?.currentSelectedEssay?.id
        );
        if (essayStore?.currentSelectedEssay?.id) {
          storyType = 'essayDetail';
        }
      }
      if (pathname.includes(MY_COLLEGES)) {
        type = AI_TEMPLATES.APPLICATION_PROMPT;
        storyType = 'application';
        if (applicationStore.isCollegeId) storyType = 'applicationDetail';
      }
    }
    setPromptType(type);
    setPromptStoryType(storyType);
  };

  return null;
};

export default PageViewTracker;
